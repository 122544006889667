import React, {
    useEffect,
    useState,
    useContext,
    useRef,
    useCallback,
  } from "react";
  // import "../../pages/Plan.css";
  import { purchaseService } from "../../services/purchase.service";

  import LogicContext from "../../Context/LogicContext";

  
  import { useForm } from "react-hook-form";

  
  import { useNavigate } from "react-router-dom";
  import GeneralModal from "../../helpers/GeneralModal";
  import { createModal } from "react-modal-promise";
  import { styled } from "@mui/material/styles";
  import Grid from "@mui/material/Grid";
  

import VendorBillingCountryComponent from "./VendorBillingCountryComponent";
import VendorShippingCountryComponent from "./VendorShippingCountryComponent";
import VendorBillingStateComponent from "./VendorBillingStateComponent";
import VendorShippingStateComponent from "./VendorShippingState";
import VendorBillingCityComponent from "./VendorBillingCityComponent";
import VendorShippingCityComponent from "./VendorShippingCityComponent";
import { GetVendorEdit} from '../../store/vendorAtom';


  
  function AddVendorAddressTab(props) {



    const [billingCountryCode, setBillingCountryCode] = useState(null);
    const [shippingCountryCode, setShippingCountryCode] = useState(null);
    const [billingStateCode, setBillingStateCode] = useState(null);
    const [shippingStateCode, setShippingStateCode] = useState(null);



  
    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const vendorToEdit = GetVendorEdit();
    const {register,setValue,getValues,handleSubmit, control, reset,formState: { errors }} = useForm();
    const logicContext = useContext(LogicContext);

    const mountRef = useRef(true);

    useEffect(()=> {
      if(vendorToEdit?.Vendor_Id){
        setValue('Billing_Attention', vendorToEdit?.Billing_Attention);
        setValue('Shipping_Attention', vendorToEdit?.Shipping_Attention);
        setValue('Billing_Address1', vendorToEdit?.Billing_Address1);
        setValue('Shipping_Address1', vendorToEdit?.Shipping_Address1);
        setValue('Billing_Address2', vendorToEdit?.Billing_Address2);
        setValue('Shipping_Address2', vendorToEdit?.Shipping_Address2);
        setValue('Billing_Zip_Code', vendorToEdit?.Billing_Zip_Code);
        setValue('Shipping_Zip_Code', vendorToEdit?.Shipping_Zip_Code);
        setValue('Billing_Phone_Number', vendorToEdit?.Billing_Phone_Number);
        setValue('Shipping_Phone_Number', vendorToEdit?.Shipping_Phone_Number);
        setValue('Billing_Fax_Number', vendorToEdit?.Billing_Fax_Number);
        setValue('Shipping_Fax_Number', vendorToEdit?.Shipping_Fax_Number);
        setValue('Shipping_Area_Id', vendorToEdit?.Shipping_Area_Id);
        setValue('Billing_Area_Id', vendorToEdit?.Billing_Area_Id);
        console.log('vendor id  ', vendorToEdit);
        setValue('Vendor_Id', vendorToEdit?.Vendor_Id);
      }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vendorToEdit])
  

  
  
    function onBillingCountrySelect(val) {
      setBillingCountryCode(val.Code);
    }
  
  
    function onShippingCountrySelect(val) {
      setShippingCountryCode(val.Code);
    }
  
    function onBillingStateSelect(val) {
      setBillingStateCode(val.Code);
    }
  
    function onShippingStateSelect(val) {
      setShippingStateCode(val.Code);
    }
  
    function onBillingCitySelect(val) {
      setValue("Billing_Area_Id", val.Code);
    }
  
    function onShippingCitySelect(val) {
      setValue("Shipping_Area_Id", val.Code);
    }
  
  
    const onSubmit = async (option) => {
      const addressForm = getValues();
      console.log('Form ', addressForm)
    
      //  validate
      if (!addressForm?.Billing_Area_Id) {
        return logicContext.showToast("Please select Billing Country, State and City", "error");
      }
      //  validate
      if (!addressForm?.Shipping_Area_Id) {
        return  logicContext.showToast("Please select Shipping Country, State and City", "error");

      }
  
  
      await myPromiseModal({title: "Confirmation", body: " Do you want to save this record?",
      showInput:false, placeHolder:''
      }).then((res) => {
          if (res.decision === true) {
          
            purchaseService.submitVendorAddress(addressForm).then((res) => {
              if (res?.data !== undefined) {
                const { message_text, message_id } = res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(message_text, "success");
                  props.onDynamicTabSelect(2)
                } else {
                  return logicContext.showToast(message_text, "error");
                }
              }
            });
          }
        }).catch((err) => {
          console.log(err)
          logicContext.showToast("error submitting record", "error",);
        })
        .catch((err) => {
          logicContext.showToast(
            "error submitting form, please try again",
            "error"
          );
        });
    };
  
    function onErrors(error) {
      console.error("Errorrrrr ", error);
      logicContext.showToast("Form error", "error");
    }
  
   
  
    return (
             <>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="container  max-auto  ">
            <Grid container spacing={2}>
            
             
  
              <Grid item xs={6}>
              <span className="text-sm font-bold">Billing Address</span>
                  <span className="text-sm  ml-2">Attention</span>
                  <span className="text-danger font-bold">*</span>
                  
                    <input
                      
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter attention "
                      {...register("Billing_Attention", {
                        required: "Billing attention is required!",
                      })}
                   
                    />
                    <small className="text-danger">
                  {errors?.Billing_Attention && errors.Billing_Attention.message}
                </small>
                 
              </Grid>
              <Grid item xs={6}>
              <span className="text-sm font-bold">Shipping Address</span>
                
                  <span className="text-sm ml-2 ">Attention</span>
                  <span className="text-danger font-bold">*</span>
                
                    <input
                    
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter attention "
                      {...register("Shipping_Attention", {
                        required: "Shipping attention is required!",
                      })}
                    />
                        <small className="text-danger">
                  {errors?.Shipping_Attention && errors.Shipping_Attention.message}
                </small>
                 
              
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Country/Region</span>
                <span className="text-danger font-bold">*</span>
                <VendorBillingCountryComponent
                  defaultVal={{ label: vendorToEdit?.Billing_Country_Name }}
                  onBillingCountrySelect={onBillingCountrySelect}
                />
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Country/Region</span>
                <span className="text-danger font-bold">*</span>
                <VendorShippingCountryComponent
                  defaultVal={{ label: vendorToEdit?.Billing_Country_Name }}
                  onShippingCountrySelect={onShippingCountrySelect}
                />
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing Address 1</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                    type="text"
                      className="bg-filterInput h-[10vh]  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Address Line 1 "
                      {...register("Billing_Address1", {
                        required: "Billing attention is required!",
                      })} />

                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Shipping Address 1</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      
                      className="bg-filterInput h-[10vh]  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Address Line 1 "
                      type="text"
                      {...register("Shipping_Address1", {
                        required: "Shipping address is required!",
                      })}
                    
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing Address 2</span>
                  <div className="">
                    <input
                      className="bg-filterInput h-[10vh]  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Address Line 2"
                      type="text"
                      {...register("Billing_Address2", {
                      })}
                    
                    
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Shipping Address2</span>
                  <div className="">
                    <input
                    type="text"
                      className="bg-filterInput h-[10vh]  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Address Line 2 "
                      {...register("Shipping_Address2", {
                      })}
                    
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <span className="text-sm ">Billing State</span>
                <span className="text-danger font-bold">*</span>
             <VendorBillingStateComponent   defaultVal={{ label: vendorToEdit?.Billing_City_Name }}
             countryCode={billingCountryCode} onBillingStateSelect={onBillingStateSelect} />
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Shipping State</span>
                <span className="text-danger font-bold">*</span>
                <VendorShippingStateComponent  defaultVal={{ label: vendorToEdit?.Shipping_City_Name }}
                 countryCode={shippingCountryCode}
                 onShippingStateSelect={onShippingStateSelect} />
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing City</span>
                  <span className="text-danger font-bold">*</span>
                  <VendorBillingCityComponent  defaultVal={{ label: vendorToEdit?.Billing_Area_Name }}
                   stateCode={billingStateCode}
                   onBillingCitySelect={onBillingCitySelect} />
               
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Shipping City</span>
                  <span className="text-danger font-bold">*</span>
                <VendorShippingCityComponent defaultVal={{ label: vendorToEdit?.Shipping_Area_Name }}
                stateCode={shippingStateCode}
                 onShippingCitySelect={onShippingCitySelect} />
                </div>
              </Grid>

            
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing Zip Code</span>
                  <div className="">
                    <input
                      className="bg-filterInput   pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter zip code "
                      {...register("Billing_Zip_Code", {
                      })}
                    
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Shipping Zip Code</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter code "
                      {...register("Shipping_Zip_Code", {
                      })}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing Phone Number</span>
                  <span className="text-danger font-bold">*</span>

                  <div className="">
                    <input
                      className="bg-filterInput   pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter phone number "
                      {...register("Billing_Phone_Number", {
                        required:"Billing phone is required",
                      })}
                    
                    />
                     <small className="text-danger">
                  {errors?.Billing_Phone_Number && errors.Billing_Phone_Number.message}
                </small>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Phone Number</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter phone number "
                      {...register("Shipping_Phone_Number", {
                        required:"Shipping phone is required",
                      })}
                    
                    />
                        <small className="text-danger">
                  {errors?.Shipping_Phone_Number && errors.Shipping_Phone_Number.message}
                </small>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Billing Fax</span>
                  <div className="">
                    <input
                      className="bg-filterInput   pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter fax "
                      {...register("Billing_Fax_Number", {
                      })}
                    
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Fax</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter fax "
                      {...register("Shipping_Fax_Number", {
                      })}
                    
                    />
                  </div>
                </div>
              </Grid>
           
              <Grid item xs={12}>
                <section>
                  <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    <div
                      type="cancel"
                      style={{ color: "#F17E7E", backgroundColor: "white" }}
                      className="text-sm  btn btn-danger rounded"
                      onClick={()=> navigate(-1)}
                    >
                      CANCEL
                    </div>
                    <div>
                      <span
                      onClick={()=> handleSubmit(onSubmit, onErrors)}
                        type="submit"
                        style={{ color: "#476EF8" }}
                        className="text-sm  px-3 rounded mr-10 py-2"
                      >
                        SAVE AS DRAFT
                      </span>

                      <button
                        type="submit"
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </section>
        </form>
      </>
       
     
    );
  }
  
  export default AddVendorAddressTab;
  