import React, {useEffect,useState,useContext, useRef,useCallback} from "react";
import Grid from "@mui/material/Grid";
// import "../../pages/Plan.css";
import { purchaseService } from "../../services/purchase.service";

import LogicContext from "../../Context/LogicContext";
import { GetEntryData} from '../../store/vendorAtom';

import { useForm } from "react-hook-form";

import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import VendorSalutationComponent from "./VendorSalutationComponent";
import VendorImageComponent from "./VendorImageComponent";
import { GetVendorEdit, SetVendorEdit} from '../../store/vendorAtom';



const AddVendorGeneralInfoTab = (props) => {
  document.title = 'Add new Vendor';
    const myPromiseModal = createModal(GeneralModal);
    const vendorToEdit = GetVendorEdit();
    const setVendorToEdit = SetVendorEdit();
    const {register,setValue,getValues,handleSubmit,control,reset,formState: { errors }} = useForm();
    const logicContext = useContext(LogicContext);
    const [selectedImage, setSelectedImage] = useState(undefined);

    const mountRef = useRef(true);

    useEffect(()=> {
      if(vendorToEdit?.Vendor_Id){
        setValue('Salutation_Id', vendorToEdit?.Salutation_Id);
        setValue('Vendor_First_Name', vendorToEdit?.Vendor_First_Name);
        setValue('Vendor_Last_Name', vendorToEdit?.Vendor_Last_Name);
        setValue('Vendor_Email_Address', vendorToEdit?.Vendor_Email_Address);
        setValue('Vendor_Display_Name', vendorToEdit?.Vendor_Display_Name);
        setValue('Vendor_Mobile_No', vendorToEdit?.Vendor_Mobile_No);
        setValue('Vendor_Image_Format', vendorToEdit?.Vendor_Image_Format);
        setValue('Vendor_Image_Id', vendorToEdit?.Vendor_Image_Id);
        setValue('vendor_image_ref', vendorToEdit?.Vendor_Image_RefNo);
        setValue('Vendor_Image_Format', vendorToEdit?.Vendor_Image_Format);
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vendorToEdit])



  function onSalutationSelect(val) {
    setValue("Payment_Term_Id", val.Code);
  }

  console.log('editable ', vendorToEdit)

  const onSubmit = async (option) => {
    const formData = getValues();


    await myPromiseModal({ title: "Confirmation",body: " Do you want to save this record?",
    showInput:false, placeHolder:'' })
      .then((res) => {
        if (res.decision === true) {
          let option = 1;
          const newData = getValues();
       
   
          const formData = new FormData();
          for (const keyData in newData) {
            formData.set(keyData, newData[keyData] ? newData[keyData] : 0);
          }
         
          if(selectedImage !== undefined){
            formData.set('file', selectedImage)
            formData.set('Vendor_Image_Id', 1)
            formData.set('vendor_image_ref', Date.now())
            var fileExt = selectedImage.name.split(".").pop();
            formData.set('Vendor_Image_Format',fileExt)

          }
          if(vendorToEdit?.Vendor_Id){
            option = 2
            formData.set('Vendor_Id', vendorToEdit.Vendor_Id);
          }
          formData.set('option',option)
        
          purchaseService.submitVendor(formData).then((res) => {
            if (res?.data !== undefined) {
              console.log('Response ', res.data)
              const { message_text, message_id, Message_Ref_No } = res.data[0];
              if (message_id === 1) {
                console.log('set this ID ', Message_Ref_No)
                setVendorToEdit((prev)=> {
                  return {...prev, Vendor_Id:Message_Ref_No}
                })
                props.onDynamicTabSelect(1);
                logicContext.showToast(message_text, "success");
                // navigate(-1);
              } else {
                return logicContext.showToast(message_text, "error");
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        logicContext.showToast("error submitting record", "error");
      })
      .catch((err) => {
        console.log('err ', err)
        logicContext.showToast( "error submitting form, please try again","error");
      });
  };

  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }


  const submitItemImage = (imageFile)=> {
    console.log(imageFile)
        setSelectedImage(imageFile);
    }



    return ( 
        <>
           <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container max-auto  ">
          <Grid container spacing={2}>
           

            <Grid item xs={6}>
              <span className="text-sm text-bold">
                Primary contacts details
              </span>
              <br></br>
              <span className="text-sm ">
                Salutation <i>(Optional)</i>
              </span>
              <VendorSalutationComponent defaultVal={{label:vendorToEdit?.Salutation_Name}} onSalutationSelect={onSalutationSelect} />

              <div className="">
                <span className="text-sm ">First Name</span>
                <span className="text-danger font-bold">*</span>
                <div className="">
                  <input
                    type="text"
                    className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter first name "
                    {...register("Vendor_First_Name", {
                      required: "First Name is required!",
                    })}
                 
                  />
                </div>
                <small className="text-danger">
                          {errors?.Vendor_First_Name && errors.Vendor_First_Name.message}
                        </small>
              </div>

              <div className="">
                <span className="text-sm ">Last Name</span>
                <span className="text-danger font-bold">*</span>
                <div className="">
                  <input
                    type="text"
                    className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter last name"
                    {...register("Vendor_Last_Name", {
                      required: "Last Name is required!",
                    })}
                  />
                </div>
                <small className="text-danger">
                  {errors?.Vendor_Last_Name && errors.Vendor_Last_Name.message}
                </small>
              </div>

            </Grid>

            <Grid item xs={6} className="flex justify-center">
              <div>
                <VendorImageComponent vendor_id={vendorToEdit?.Vendor_Id}
                format={vendorToEdit?.Vendor_Image_Format} submitItemImage={submitItemImage} />

                <div>
                  {" "}
                  <i>image should not exceeding 5 MB</i>
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <span className="text-sm ">Email</span>
              <input
                type="email"
                className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                placeholder="Enter Email"
                {...register("Vendor_Email_Address", {
                })}
              />
            </Grid>

            <Grid item xs={6}>

              <span className="text-sm ">Company Name</span>
              <span className="text-danger font-bold">*</span>
              <input
                type="text"
                className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                placeholder="Enter Company Name"
                {...register("Vendor_Company_Name", {
                  required: "Company Name is required!",
                })} />
                  <small className="text-danger">
                  {errors?.Company_Name && errors.Company_Name.message}
                </small>
                <br />
                <br />
              

              <span className="text-sm ">Display Name</span>
              <span className="text-danger font-bold">*</span>
              <input
                type="text"
                className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                placeholder="Enter Display Name"
                {...register("Vendor_Display_Name", {
                  required: "Display Name is required!",
                })} />
                  <small className="text-danger">
                  {errors?.Vendor_Display_Name && errors.Vendor_Display_Name.message}
                </small>
              
            </Grid>
            <Grid item xs={6}>
              <span className="text-sm ">Work Phone Number</span>
              <span className="text-danger font-bold">*</span>
              <input
                type="text"
                className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                placeholder="enter work phone number"
                {...register("Vendor_Mobile_No", {
                  required: "Mobile Number is required!",
                })}
              />
                  <small className="text-danger">
                  {errors?.vendor_Mobile_No && errors.vendor_Mobile_No.message}
                </small>

            </Grid>
            <Grid item xs={6}>
              <button
                type="submit"
                style={{ backgroundColor: "#476EF8" }}
                className="text-sm text-white px-3 rounded py-2 mt-4 "
              >
                ADD ANOTHER CONTACT PERSON
              </button>
            </Grid>

            <Grid item xs={12}>
              <section>
                <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                  <button
                    type="cancel"
                    style={{ color: "#F17E7E", backgroundColor: "white" }}
                    className="text-sm rounded"
                  >
                    CANCEL
                  </button>
                  <div>
                    <span
                      type="submit"
                      style={{ color: "#476EF8" }}
                      className="text-sm  px-3 rounded mr-10 py-2"
                    >
                      SAVE AS DRAFT
                    </span>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#476EF8" }}
                      className="text-sm text-white px-3 rounded py-2"
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </section>
            </Grid>
          </Grid>
        </section>
      </form>
        </>
     );
}
 
export default AddVendorGeneralInfoTab;