import React, { useEffect, useState, useContext, useRef } from "react";
import { GetEditCreditNoteAtom, SetEditCreditNoteAtom } from "../../store/CreditNoteStore";
import LogicContext from "../../Context/LogicContext";
import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import AddInvoiceCustomer from "../invoice_page/AddInvoiceCustomer";
import CustomerSearchSelect from "./CustomerSearchSelect";
import { creditNoteService } from "../../services/creditNoteService";


function CreditNoteAddNew() {

  const getCreditNoteEdit = GetEditCreditNoteAtom();
  const setCreditNoteEdit = SetEditCreditNoteAtom();


  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const logicContext = useContext(LogicContext);
  const [customer, setCustomer] = useState({});




  useEffect(() => {

    if (getCreditNoteEdit?.Ref_No) {
      setValue("customer_id", getCreditNoteEdit?.Customer_Id);
      setValue("amount", getCreditNoteEdit?.Amount);
      setValue("remark", getCreditNoteEdit?.Remark);
      setValue("ref_no", getCreditNoteEdit?.Ref_No);
      const tempCustomer = {Email_Address:getCreditNoteEdit?.Customer_Email_Address,
         Name: getCreditNoteEdit?.Customer_Full_Name ,Customer_Id:getCreditNoteEdit?.Customer_Id };
    setCustomer(tempCustomer);
   
    }

    return () => {
        setCreditNoteEdit();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCreditNoteEdit]);





  
  const onSubmit = async () => {
    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((res) => {
        if (res.decision === true) {
          const newData = getValues();
          let option = 1;
          if(getCreditNoteEdit?.Ref_No){
            option = 10;
          }
          

          creditNoteService.submitCreditNote({...newData, option}).then((res) => {
              if (res?.data !== undefined) {
                console.log('RESSS ', res.data[0]);
                const { message_text: Message_text, message_id } = res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(Message_text, "success");
                  navigate(-1);
                } else {
                  return logicContext.showToast(Message_text, "error");
                }
              }
            });
        }
      })
      .catch((err) => {
        console.log('Error ', err);
        logicContext.showToast("error submitting record", "error");
      });
  };


  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }



  function TransactionDateFunction(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(getCreditNoteEdit?.Transaction_Date !== undefined){
       defaultDate = moment(getCreditNoteEdit?.Transaction_Date).format("YYYY-MM-DD")
    }
   
    return (
      <input 
      defaultValue={defaultDate}
      className="bg-white h-[8vh] pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
      type="date"
      {...register("transaction_date", {
        required: "Transaction date is required",
      })}
    />
    )
  }


  const onCustomerSelected = (customer)=> {
    setValue("customer_id", customer?.Customer_Id);
    setCustomer(customer);

  }



  return (
    <>
      <>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="container max-auto  mt-5 ">
            <Grid container spacing={2}>
            
              <Grid item xs={6}>
                

              <span className="text-sm text-danger ">Customer Name</span> 
              <Grid container>
             
              <Grid item xs={10}>
               <CustomerSearchSelect  {...register("customer_id", { required: "Customer is required" })} 
                onCustomerSelected={onCustomerSelected} />
                <small className="text-red-500">
                {Boolean(errors["customer_id"]?.message) && String(errors["customer_id"]?.message)}
              </small>

               {/* custoner details */}
               {customer?.Customer_Id && 
                 <div class="max-w-md mx-auto bg-white rounded-lg shadow-md  p-4">
                 <ul class="space-y-3">
                 <li class="flex items-center bg-blue-100 p-1 rounded">
                     <span class="w-4 h-4 bg-blue-500 rounded-full mr-3"></span>
                     <span> Email: {customer?.Email_Address} </span>
                 </li>
                 <li class="flex items-center bg-blue-100 p-1 rounded">
                     <span class="w-4 h-4 bg-blue-500 rounded-full mr-3"></span>
                     <span>Name: {customer?.Name} </span>
                 </li>
                 </ul>
             </div>
               
               }
             
               {/* custoner details end */}
                </Grid>

              </Grid>
              
                
              </Grid>
            

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Amount</span>
                  <div className="">
                    <input
                      className="bg-white h-[8vh] pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                       placeholder="enter Amount"
                   
                    {...register("amount", { required: "Amount is required" })} 
                    />
                      <small className="text-red-500">
                {Boolean(errors["amount"]?.message) && String(errors["amount"]?.message)}
              </small>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm text-danger ">Transaction Date</span>
                  <div className="">
                 {TransactionDateFunction()}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Remark</span>
                  <div className="">
                    <textarea
                      className="bg-white h-[8vh] w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      {...register("remark", {
                      })}
                   
                    />
                  </div>
                </div>
              </Grid>
        
              <Grid item xs={12}>
                <section>
                  <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    <div
                      style={{ color: "#F17E7E", backgroundColor: "white" }}
                      className="text-sm btn btn-danger rounded" onClick={()=> navigate(-1)} >
                      CANCEL
                    </div>
                    <div>
                      {/* <span
                        style={{ color: "#476EF8" }}
                        className="text-sm cursor   px-3 rounded mr-10 py-2"
                      >
                        SAVE AS DRAFT
                      </span> */}
                      <button
                        type="submit"
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </section>
        </form>
      </>
    </>
  );
}

export default CreditNoteAddNew;
