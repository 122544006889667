import React, { useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import InvoiceTable from "./InvoiceTable";
import { invoiceService } from "../../services/invoice.service";
import LogicContext from "../../Context/LogicContext";
import {
  SetInvoiceList,
  GetInvoiceList,
  SetInvoiceAccSummary,
  GetInvoiceAccSummary,
  SetInvoiceFilter,
  GetInvoiceFilter,
  SetInvoiceTableHeader,
  SetInvoiceBulkAction,
  GetInvoiceBulkAction,
} from "../../store/invoiceAtom";
import CurrencyFormat from "react-currency-format";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";

function InvoicePage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const setInvoiceList = SetInvoiceList();
  const getInvoiceList = GetInvoiceList();
  const myPromiseModal = createModal(GeneralModal);
  const getInvoiceFilters = GetInvoiceFilter();
  const invoiceSummary = GetInvoiceAccSummary();
  const setInvoiceSummary = SetInvoiceAccSummary();
  const setBulkActions = SetInvoiceBulkAction();
  const getBulkActions = GetInvoiceBulkAction();
  const setBulkFilter = SetInvoiceFilter();
  const setInvoiceTableHeader = SetInvoiceTableHeader();
  const [tableData, setTableData] = useState(getInvoiceList);
  const [itemSelected, setItemSelected] = useState([]);

  useEffect(() => {
    document.title = "Invoice";
    if (getInvoiceList.length < 1) {
      invoiceService.getInvoiceData().then((response) => {
        setInvoiceList(response.data["Invoice Info"]);
        setTableData(response.data["Invoice Info"]);
        setInvoiceSummary(response.data["Amount Summary"][0]);
        setBulkFilter(response.data["Filtering Criteria"]);
        setInvoiceTableHeader(response.data["Display Column"]);
        setBulkActions(response.data["Bulk Action"]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInvoiceList]);

  const getInvoiceData = async () => {
    try {
      const response = await invoiceService.getInvoiceData();

      if (response?.data !== undefined) {
        setInvoiceList(response.data["Invoice Info"]);
        setTableData(response.data["Invoice Info"]);
        setInvoiceSummary(response.data["Amount Summary"][0]);
        setBulkFilter(response.data["Filtering Criteria"]);
        setInvoiceTableHeader(response.data["Display Column"]);
        setBulkActions(response.data["Bulk Action"]);
      }
    } catch (error) {
      logicContext.showToast("connection error getting invoice data", "error");
    }
  };

  const onSelectBulkAction = (action) => {
    const option = action.target.value;
    if (option === "") {
      return setTableData(getInvoiceList);
    }
    var newList = getInvoiceList.filter(
      (item) => item.Invoice_Status_Id === option
    );
    setTableData(newList);
  };

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  async function bulkActions(action) {
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        itemSelected.map((item) => {
          temp += `<a> <refno> ${item.Invoice_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        invoiceService
          .invoiceBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return getInvoiceData();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  function addNewInvoice() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-invoice`);
  }

  return (
    <>
      <section>
        <section className="pt-8 grid grid-auto-fit-[180px] gap-3 w-full">
          <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
            <h1 className="text-center text-3xl text-primary font-bold">
              <CurrencyFormat
                style={{ color: "#476EF8" }}
                value={parseFloat(0)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </h1>
            <div className="mt-3 text-sm text-gray-700 text-center font-semibold uppercase">
              Total Outstanding Receivables
            </div>
          </div>

          <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
            <div className="text-center text-3xl text-primary font-bold">
              <CurrencyFormat
                style={{ color: "#476EF8" }}
                value={parseFloat(0)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </div>
            <div className="mt-3 text-sm text-gray-700 text-center font-semibold uppercase">
              Due Today
            </div>
          </div>

          <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
            <div className="text-center text-3xl text-primary font-bold">
              <CurrencyFormat
                style={{ color: "#476EF8" }}
                value={parseFloat(0)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />{" "}
            </div>
            <div className="mt-3 text-sm text-gray-700 text-center font-semibold uppercase">
              Due Within 30 Days
            </div>
          </div>

          <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
            <div className="text-center text-3xl text-primary font-bold">
              <CurrencyFormat
                style={{ color: "#476EF8" }}
                value={parseFloat(0)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />{" "}
            </div>
            <div className="mt-3 text-sm text-gray-700 text-center font-semibold uppercase">
              Overdue Invoice
            </div>
          </div>

          <div className="bg-white border-[1px] border-gray-200 py-3 px-3 rounded-xl">
            <div className="text-center text-3xl text-primary font-bold">
              <CurrencyFormat
                style={{ color: "#476EF8" }}
                value={parseFloat(0)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />{" "}
            </div>
            <div className="mt-3 text-sm text-gray-700 text-center font-semibold uppercase">
              Average No. of Days for Getting Paid
            </div>
          </div>
        </section>
        <section className="mt-8 md:flex items-center justify-between space-y-4 md:space-y-0">
          <div className="flex items-center space-x-2">
            {itemSelected.length > 0 ? (
              <div className="row">
                <div className="col col-md-4">
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: "#E5E5E5",
                        border: "none",
                        color: "#45444B",
                        fontSize: "12px",
                        height: "45px",
                      }}
                      id="dropdown-basic"
                    >
                      Bulk Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {getBulkActions?.map((action, i) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={() => bulkActions(action)}
                          >
                            {action.Action_Name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <ActionPaneComponent
                bulkActionData={getInvoiceFilters}
                onSelectBulkAction={onSelectBulkAction}
              />
            )}

            <Tooltip title="Reload">
              <div
                onClick={() => getInvoiceData()}
                className="h-fit w-fit p-2 rounded-full cursor-pointer hover:bg-gray-200 active:bg-gray-300 "
              >
                <IoMdRefresh className="h-[20px] w-[20px] text-gray-600 " />
              </div>
            </Tooltip>
          </div>

          <div className="flex items-center space-x-4">
            <button className="font-[Poppins] font-semibold py-2 px-3 rounded text-sm">
              IMPORT
            </button>
            <div className="btn-group">
              <button
                className=" font-[Poppins] font-semibold py-2 px-3 rounded text-sm"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                EXPORT
              </button>
            </div>

            <button
              onClick={addNewInvoice}
              className="bg-buttonColor font-[Poppins] font-semibold py-2 px-3 rounded text-white text-sm"
            >
              ADD NEW
            </button>
          </div>
        </section>

        <section className="mt-5">
          <InvoiceTable
            tableData={tableData}
            selectedTableItems={selectedTableItems}
          />
        </section>
      </section>
    </>
  );
}

export default InvoicePage;
