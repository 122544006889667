import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";

import {  useParams } from "react-router-dom";
import {accountService} from '../../services/accountService';
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { purchaseService } from "../../services/purchase.service";
import LogicContext from "../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
import { Table } from "react-bootstrap";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatCustomNumber } from "../../helpers/formatCustomNumber";




function PurchaseOrderBill(props) {
  const { purchase_id } = useParams(); 
  const [billDetails, setBillDetails] = useState({});
const myPromiseModal = createModal(GeneralModal);

  const logicContext = useContext(LogicContext); 




  useEffect(()=> {
    let isMount = true;
    accountService.getPurchaseBill(purchase_id).then(response => {
      console.log('Bill ', response.data)
      if(response.data !== undefined && isMount){
        setBillDetails(response.data);

      }
    });

    return ()=> {
      isMount = false;
    }
  },[purchase_id])



  const deleteBillHandler = async (bill) => {
    console.log('BILL Del ', bill)
    console.log('BILL Del ', billDetails)
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${bill?.Bill_No} ?`,showInput:false, placeHolder:'',
    }).then((response) => {
      if (response.decision === true) {
        let option = 12;
        let temp = `<a> <refno> ${bill?.Bill_Ref_No} </refno> </a>`;
        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService.setBillBulkAction({xml_string_refno, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
           const newBillList =  billDetails?.Bill_Information.filter(item => item.Bill_Ref_No !== bill.Bill_Ref_No)
           setBillDetails({...billDetails, Bill_Information:  newBillList})
           props.reloadPurchaseOrder();
          }
      });
      }
    });
  };




  return (
    <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
      <Grid className="mt-2   " container spacing={2}>
        <Grid className=" border-2 ml-3" container spacing={2}>
          <Grid item xs={12}>
          <div className="mt-2 ">
  
  <TableContainer className="mt-3 w-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {/* <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup> */}
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell>Bill</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell> Due Date </TableCell>
                  <TableCell> Amount </TableCell>
                  <TableCell> Balance Due </TableCell>
                  <TableCell>  </TableCell>
         
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {billDetails?.Bill_Information?.map((item, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="text-primary" onClick={()=> props?.viewBillDetails(item?.Bill_Ref_No)}  align="left">{item.Bill_No} </TableCell>
                      <TableCell align="left">{item.Bill_Date}  </TableCell>
                      <TableCell align="left">{item.Bill_Status_Name}  </TableCell>
                      <TableCell align="center">{item.Due_Date} </TableCell>
                  
                      <TableCell align="left">
                        <CurrencyFormat value={formatCustomNumber(item.Bill_Amount)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                
                      <TableCell align="left">
                        <CurrencyFormat value={formatCustomNumber(item.Balance_Due_Amount)}
                         displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                       </TableCell>
                
                      <TableCell align="left">
                       <DeleteForeverIcon onClick={()=> deleteBillHandler(item)}
                        fontSize="large"  className="text-danger" />
                       </TableCell>
                
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
      
          </Grid>

         
        </Grid>
      </Grid>

 
     
    </section>
  );
}

export default PurchaseOrderBill;
