import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { GetManualJournalAtom, SetManualJournalAtom, SetManualJournalEditAtom } from "../../store/manualJounalAtom";

import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { creditNoteService } from "../../services/creditNoteService";
import { useReactToPrint } from 'react-to-print';
import CreditNoteOptions from "../credit_note/CreditNoteOptions";
import ManualJournalDetailsContent from "./ManualJournalDetailsContent";
import CreditNoteScroll from "../credit_note/CreditNoteScroll";
import { accountService } from "../../services/accountService";
import ManualJournalScroll from "./ManualJournalScroll";
// import CreditNoteFilter from "./CreditNoteFilter";



function ManualJournalDetailsPage() {
  const myPromiseModal = createModal(GeneralModal);
  const setJournalEdit = SetManualJournalEditAtom();
  const getManualJournalAtom = GetManualJournalAtom();
  const setManualJournalAtom = SetManualJournalAtom();
  
  const logicContext = useContext(LogicContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const componentRef = useRef();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const [details, setDetails] = useState({});
    const [detailsList, setDetailsList] = useState([]);



    useEffect(()=> {
      loadDetails(id);
        
    },[id]);


    useEffect(() => {
      document.title = 'Manual Journal details';
      if (!!getManualJournalAtom.account_info?.length) {
        loadTableList();
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getManualJournalAtom.credit_note]);


    const loadTableList = () => {
      
      accountService.manualJournal().then(res => {
        if (res.data) {
          // setManualJournalAtom(res.data);
        }
      });
    }

    const loadDetails = async(accountId)=> {
      console.log('Load_DEtails,,,,');
        try {
        const response =  await accountService.manualJournalDetails(accountId);
        console.log('JOURNAL_DET ', response.data?.account_info);
        if(response.data?.account_info?.length){
          const journal = response.data?.account_info[0];
          console.log('SELLL ', journal);
            setDetails(journal);
            setDetailsList(response.data?.account_info);
          //  const newList = getManualJournalAtom.account_info?.map((item)=> {
          //   if(item.Ref_No === creditNote.Ref_No){
          //     return creditNote;
          //   }else{
          //     return item;
          //   }
          //  });
          //  setCreditNoteListAtom({credit_note: newList,status_info: getCreditNoteListAtom.status_info});

         
        }
            
        } catch (error) {
            console.log('Error ', error);
            
        }
    }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });



  
  const addNewRecord = ()=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  
  const viewCustomerDetails = (id)=> {
    navigate(`/${currModuleId}/${currModuleName}/customers/customer-details/${id}`);
  }


      const editCreditNoteOrder = async () => {
        const response = await myPromiseModal({
          title: "Modify Credit Note",
          body: " Do you want to modify this record?",
          showInput: false,
          placeHolder: "",
        });
    
        if (response.decision === true) {
          setJournalEdit(details);
          navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
        }
      };


  const onStatusChange =  async(selection) => {
    console.log('selection ', selection);
    // return ;
    // const formData = {ref_no: id, option: selection?.Credit_Note_Status_Id};
    // const response = await myPromiseModal({ title: `Change status to ${selection?.Credit_Note_Status_Name}`,
    //   body: " Do you want to modify this record?",  showInput: false, placeHolder: "",
    // });
    // console.log('rs ', response);
    // if(response?.decision === true){
    //   creditNoteService.creditNoteStatus(formData).then((res)=> {
    //     console.log('status_success ', res.data);
    //     loadDetails(id);
    //     const responseData = res?.data[0];
    //     if(responseData?.message_id == 1){
    //       logicContext.showToast(responseData?.message_text, "success");
    //     }
        
    //   }).catch((err)=> {
    //     console.log('errrrroor ', err);
    //   });

    // }

 
  };
    
    

    return ( 
        <>
          <section className="">
        <section className="flex flex-wrap justify-between">
          <div className="mt-3">
            {/* {selectedList.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "40px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-32 px-2 text-sm shadow-lg">
                  {getBulkActions?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        className="px-0 text-gray-600"
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )} */}
          </div>

          <div className="flex flex-row flex-wrap items-center">
            <div className="mt-3 flex flex-row items-center mr-6">
          <button onClick={addNewRecord} className="btn btn-primary m-4" >Add New</button>
              Status
            {/* <CreditNoteOptions bulkActionData={getManualJournalAtom?.status_info}
            onFilterSelect={onStatusChange}
             /> */}

            </div>
            <div className="mt-3 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp  onClick={handlePrint} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdEdit onClick={editCreditNoteOrder} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              {/* <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div> */}
            </div>
          </div>
        </section>
        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <ManualJournalScroll
            ></ManualJournalScroll>
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4 ">
          
          {/* Paste here */}
          
            <ManualJournalDetailsContent viewCustomerDetails={viewCustomerDetails}
             ref={componentRef} detailsList={detailsList}  id={id} details={details} />
          {/* Paste end */}
          </section>
        </section>
      </section>
        
        </>
     );
}

export default ManualJournalDetailsPage;