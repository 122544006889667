import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import moment from "moment";
import ManualJournalType from "./ManualJournalTypeSelect";
import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { GetManualJournalAtom } from "../../store/manualJounalAtom";
import { accountService } from "../../services/accountService";
import LogicContext from "../../Context/LogicContext";
import GeneralFileUploadPreview from "../../components/GeneralFileUploadPreview";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
  // Add account options array
  let accountOptions = [
    { account_id: 1, account_name: "Example 1" },
    { account_id: 2, account_name: "Example 2" },
    { account_id: 3, account_name: "Example 3" },
    { account_id: 4, account_name: "Example 4" },
    
    // Add more account options as needed
  ];
  // Add account options array
  let taxOptions = [
    { tax_id: 1, tax_name: "Example 1" },
    { tax_id: 2, tax_name: "Example 2" },
    { tax_id: 3, tax_name: "Example 3" },
    { tax_id: 4, tax_name: "Example 4" },
    
    // Add more account options as needed
  ];
  

  let credit = 0;
  let debt = 0;
  let debtDiff = 0;
  let creditDiff = 0;


const NewManualJournal = () => {
  const journalAtom  = GetManualJournalAtom();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [attachmentFile, setAttachmentFile] = useState(null);

  const myPromiseModal = createModal(GeneralModal);
  const { register,setValue, getValues, handleSubmit,control, watch, reset,formState: { errors },
} = useForm();

const { fields, append, remove } = useFieldArray({ control, name: "journal_entries"});


useEffect(()=> {

  if(journalAtom.chart_of_account){
    accountOptions = journalAtom.chart_of_account;
  }

  if(journalAtom.tax_info){
    taxOptions = journalAtom.tax_info;
  }
  addRow();

},[journalAtom?.chart_of_account]);

  const addRow = () => {
    append({   account_id: "", remark: "" , debit_account: 0, credit_account: 0 });
  };


  const removeRow = (index) => {
    console.log('FEE ', fields);
    if (fields.length > 1) {
      remove(index);
    }
      credit = 0;
      debt = 0;
      debtDiff = 0;
      creditDiff = 0;
      calculateDebtCredit(fields)

  };

  const onTypeSelect = (sel)=> {
    console.log('type.... ', sel);
    setValue('journal_type_id', sel.journal_type_id);
  }

  const onTransSelect = (sel)=> {
    console.log('type.... ', sel);
    setValue('transaction_type_id', sel.journal_type_id);
  }


  function journalDateDateFunction() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    // if (getEditReturn?.Return_Date_String !== undefined) {
    //   defaultDate = moment(getEditReturn?.Return_Date_String).format("YYYY-MM-DD");
    // }

    return (
      <div className="">
    
        <input
        defaultValue={defaultDate}
        key={defaultDate}
              type="date"
              className="w-full border rounded px-3 py-2"
              {...register("journal_date", {
                required: "Journal date is required",
              })}
            />
        <br />
                <small className="text-red-500">
                    {Boolean(errors["journal_date"]?.message) &&
                        String(errors["journal_date"]?.message)}
                </small>
    </div>

    );
  }

  function onFileSelected(file) {
    setAttachmentFile(file);
    
  }

  const calculateDebtCredit = ((watchData)=> {
    if(!watchData?.length)
      return;
    
    watchData.forEach((table)=> {
      credit += table.credit_account  || 0;
      debt += table.debit_account  || 0;

    });
    
    if(credit > debt){
      creditDiff = credit - debt;
    }

    if(debt > credit){
      debtDiff = debt - credit ;
    }

  });


  const watchAllFields = watch();
  console.log('WATCH ', watchAllFields);
  calculateDebtCredit( watchAllFields.journal_entries);
  


 

  const onSubmit = async (option) => {
    const formData = getValues();
    const apiOption = 1;
    console.log('Data... ',  formData);
    let temp = ``;
   formData?.journal_entries.forEach(element => {
      temp +=  `<a><account_id>${element?.account_id}</account_id>
      <debit_amount>${element?.debit_account} </debit_amount>
      <credit_amount>${element?.credit_account} </credit_amount>
      <tax_id>${element?.tax_id} </tax_id>
      <remark>${element?.remark} </remark></a>`
      
    });
   
    let newFormData =    {
      "journal_date": formData.journal_date,
      "reference_number": formData.reference_number,
      "journal_type_id": formData?.journal_type_id,
      "transaction_type_id": formData?.transaction_type_id,
      "notes": formData.notes,
      "currency_id": '',
      "xml_string":`<root>${temp} </root>` ,
   }
    
    await myPromiseModal({
      title: "Create New Manual Journal?", body: `A new Manual Journal will be created!`,
      showInput: false, placeHolder: '',
    

  }).then((res) => {
    const formData = new FormData();
   
    for (const keyData in newFormData) {
      formData.set(keyData, newFormData[keyData] ? newFormData[keyData] : 0);
    }
    formData.set("option", apiOption);
    
    if(attachmentFile){
      formData.set('file', attachmentFile)
    }
    
    formData.delete('journal_entries'); // delete list
    accountService.createNewJournal(formData).then((res)=> {
      console.log('response... ', res.data);
      const data =  logicContext.httpResponseManager(res.data[0]);
      logicContext.showToast(data?.message_text, 'success');
      navigate(-1);
    }).catch((err)=> {
      console.log('error ',err);
    })
   


      // }else{
      //   rewardService.createNewReturn(formData).then((response)=> {
      //     console.log('success_new', response.data);
      //     const data =  logicContext.httpResponseManager(response.data[0]);
      //     if(data?.message_id == 1){
      //       logicContext.showToast(data?.message_text, 'success');
      //       navigate(-1);
      //     }
      //   });

      // }
   
    // }

  });

    //  validate

  };



  return (
    <form onSubmit={handleSubmit(onSubmit)}>

  
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-xl font-bold mb-4">New Journal</h1>
      <div className="space-y-4">
        {/* Input Fields */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className=" font-semibold mb-1">Date*</label>
            {journalDateDateFunction()}
          </div>
          <div>
            <label className=" font-semibold mb-1">Journal#*</label>
            <input
              type="number"
              className="w-full border rounded px-3 py-2"
            />
          </div>
          <div>
            <label className=" font-semibold mb-1">Reference#</label>
            <input
              type="text"
              className="w-full border rounded px-3 py-2"
              {...register("reference_number", {
                // required: "Journal date is required",
              })}
            />
          </div>
          <div>
            <label className=" font-semibold mb-1">Notes*</label>
            <textarea
              className="w-full border rounded px-3 py-2"
              rows={2}
              maxLength={500}
              {...register("notes", {
                // required: "Journal date is required",
              })}
            ></textarea>
          </div>
          <div>
            <label className=" font-semibold mb-1">Journal Type</label>
            <ManualJournalType onTypeSelect={onTypeSelect} />
          </div>
          <div>
            <label className=" font-semibold mb-1">Journal Type</label>
            <ManualJournalType onTypeSelect={onTransSelect} />
          </div>
          <div>
            <label className=" font-semibold mb-1">Currency</label>
            <select className="w-full border rounded px-3 py-2">
              <option>NGN - Nigerian Naira</option>
              {/* Add more options if needed */}
            </select>
          </div>
        </div>

        {/* Table */}
        <div>

        <table className="w-full border border-gray-300 text-left">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 border">Account</th>
                  <th className="p-2 border">Description</th>
                  <th className="p-2 border">Tax</th>
                  <th className="p-2 border">Debits</th>
                  <th className="p-2 border">Credits</th>
                  <th className="p-2 border"></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => (
                  <tr key={field.id}>
                    <td className="p-2 border">
                    <select
                  className="w-full border rounded px-2 py-1"
                  {...register(`journal_entries.${index}.account_id`)}
                >
                  <option value="">Select </option>
                  {accountOptions.map(account => (
                    <option key={account.account_id} value={account.account_id}>
                      {account.account_name}
                    </option>
                  ))}
                </select>
                    </td>
                    <td className="p-2 border">
                      <input
                        type="text"
                        className="w-full border rounded px-2 py-1"
                        placeholder="Description"
                        {...register(`journal_entries.${index}.remark`)}
                      />
                    </td>
                 
                    <td className="p-2 border">
                     
                    <select
                  className="w-full border rounded px-2 py-1"
                  {...register(`journal_entries.${index}.tax_id`)}
                >
                  <option value="">Select </option>
                  {taxOptions.map(tax => (
                    <option key={tax.tax_id} value={tax.tax_id}>
                      {tax.tax_name}
                    </option>
                  ))}
                </select>
                    </td>
                    <td className="p-2 border">
                      <input
                        type="number"
                        step="0.01"
                        className="w-full border rounded px-2 py-1"
                        {...register(`journal_entries.${index}.debit_account`, { 
                          valueAsNumber: true 
                        })}
                      />
                    </td>
                    <td className="p-2 border">
                      <input
                        type="number"
                        step="0.01"
                        className="w-full border rounded px-2 py-1"
                        {...register(`journal_entries.${index}.credit_account`, { 
                          valueAsNumber: true 
                        })}
                      />
                    </td>
                    <td className="p-2 border text-center">
                      <button
                        type="button"
                        className="text-red-500"
                        onClick={() => removeRow(index)}
                      >
                        ✕
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
      
          <button  type="button"
            className="mt-4 text-blue-500"
            onClick={addRow}
          >
            + Add New Row
          </button>
        </div>

        {/* Sub Total */}
        <div className="mt-4 grid grid-cols-2 gap-6 items-center">
          <div>
          <div className="">
                  <span className="text-sm ">Attach File</span> 
                <GeneralFileUploadPreview 
                // defaultFileUrl={editableSalesOrder?.Sales_Order_Attachement_URL} 
                onFileSelected={onFileSelected} />
                  <i> maximum file size 2MB </i>
                </div>
          </div>
          <div className="text-center">
            <p className="font-semibold">Sub Total: <span className="mr-2">{formatCustomNumber(debt)} </span> <span className="ml-2">{formatCustomNumber(credit) } </span></p>
            <p className="font-semibold">Total (NGN): <span className="mr-2">{formatCustomNumber(debt)} </span> <span className="ml-2">{formatCustomNumber(credit) } </span></p>
            <p className="font-semibold text-red-500">Difference: <span className="mr-2 text-red-500">{formatCustomNumber(debtDiff)} </span> <span className="ml-2 text-red-500">{formatCustomNumber(creditDiff) } </span> </p>
          </div>
        </div>
      </div>
      {/* buttons */}
      <div className="flex space-x-4 mt-6">
      <button className="bg-blue-700 text-white px-4 py-2 rounded hover:bg-gray-800">
        Save and Publish
      </button>
      <button className="bg-white text-gray-700 border border-gray-300 px-4 py-2 rounded hover:bg-gray-100">
        Save as Draft
      </button>
      <button type="button" onClick={()=> navigate(-1)} className="bg-white text-gray-700 border border-gray-300 px-4 py-2 rounded hover:bg-gray-100">
        Cancel
      </button>
    </div>
      {/* buttons end */}
    </div>
    </form>
  );
};

export default NewManualJournal;
