import { useEffect, useState } from "react";


const ChartAccountShowAsActive = ({ defaultVal, addShowAsActive}) => {
    const [isChecked, setIsChecked] = useState(false); 
    
    useEffect(()=> {
        if(defaultVal === 'A'){
            setIsChecked(true)
        }
      },[defaultVal]);

      const handleOnChange = (ev) => {
          const decision =  ev.target.checked;
          console.log('XSX ', decision);
        setIsChecked(decision);
        if(isChecked){
            addShowAsActive('A')
        }else{
            addShowAsActive('I')
        }
        
      };


    return ( 
        <>
           <input
           checked={isChecked}  onChange={(ev)=> handleOnChange(ev)} 
            type="checkbox"
            className="w-[0.8vw] h-[1.6vh]"
            value="1"
            />
        </>
     );
}
 
export default ChartAccountShowAsActive;