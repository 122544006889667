
import { useEffect, useState } from "react";

import { GetInvoiceList, GetInvoiceFilter } from "../../store/invoiceAtom";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { twMerge } from "tailwind-merge";
import { GetManualJournalAtom } from "../../store/manualJounalAtom";

// import CreditNoteFilter from "./CreditNoteFilter";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ManualJournalFilter from "./ManualJournalFilter";



function ManualJournalScroll() {
  const jounrnalAtom = GetManualJournalAtom();
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const {  id } = useParams();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    setTableData(jounrnalAtom?.account_info);
  }, [jounrnalAtom]);

  const onFilterSelect = (selection) => {
    // if(selection == 0){
    //    setTableData(jounrnalAtom?.account_info);
    //    return;
    // }
    // const newList =  jounrnalAtom?.account_info?.filter((item)=> item?.Status_Id == selection);
    // if(newList?.length){
    //   setTableData(newList);
    // }else{
    //   setTableData([]);
    // }
  };


  function viewItemDetails(item) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${item.account_ref_no}`
    );
  }


  const manageInvoiceStatus = (status) => {
    let defaultClass = "text-primary";

    switch (status) {
      case "Draft":
        defaultClass = "text-danger";
        break;
      case "Approved":
        defaultClass = "text-success";
        break;
      case "Void":
        defaultClass = "text-warning";
        break;
      case "Pending Approval":
        defaultClass = "text-info";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-medium ${defaultClass} `}>{status}</span>
    );
  };

  return (
    <>
      <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        <div className="border-b-[1px] border-b-gray-300">
          <ManualJournalFilter
            bulkActionData={jounrnalAtom?.journal_type}
            onFilterSelect={onFilterSelect}
          />
        </div>
        {tableData.map((journal, i) => {
          return (
            <div
              className={`p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                id == journal?.account_ref_no  ? "bg-gray-200"  : "hover:bg-gray-100"
              }`}
            >
              <div className="flex flex-row">
                <div className="">
                  {/* <input
                    onChange={(e) => onCheckAll(e, i)}
                    checked={note?.checked ? note?.checked : ""}
                    type="checkbox"
                    className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
                  /> */}
                </div>

                <div
                  onClick={() => viewItemDetails(journal)}
                  className="ml-1"
                >
                  <div className="text-sm font-bold text-gray-700">
                    {journal?.Credit_Note_Number}
                  </div>
                  {/* <div className="text-xs text-gray-400 font-semibold italic">
                    {note?.Invoice_Ref_No}
                  </div> */}
                  <div className="text-sm lg:text-xs text-gray-600">
                    {moment(journal?.Transaction_Date).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
              <div
                onClick={() => viewItemDetails(journal)}
                className="lg:mt-2 lg:ml-5 "
              >
                <div className={twMerge("text-sm font-normal")}>
                  {manageInvoiceStatus(journal?.account_ref_no)}
                </div>

                <div className="mt-[2px] text-sm lg:text-xs font-bold text-gray-700">
                  <CurrencyFormat
                    value={parseFloat(journal?.Amount)?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default ManualJournalScroll;
