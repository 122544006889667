/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import LogicContext from "../../Context/LogicContext";

import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CarryBagAddCustomer from "./CarryBagAddCustomer";
import { rewardService } from "../../services/Reward.service";
import { GetEditReturn, SetEditReturn } from "../../store/carrierBagReturn";


function CarrierBagAddNewPage() {


  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register,setValue, getValues, handleSubmit,control, reset,formState: { errors },
  } = useForm();
  const logicContext = useContext(LogicContext);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const getEditReturn = GetEditReturn();
  const setEditReturn = SetEditReturn();





  useEffect(() => {
  
    if(getEditReturn?.Ref_No){
      setValue('ref_no', getEditReturn?.Ref_No);
      setValue('customer_id', getEditReturn?.Customer_Id);
      setValue('return_date', getEditReturn?.Return_Date_String);
      setValue('return_quantity', getEditReturn?.Bag_Quantity);
  
    
   
    }
    
    return () => {
      setEditReturn({});
     
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditReturn?.Ref_No]);







  function onCustomerSelect(customer) {
    setValue("customer_id", customer.Customer_Id);
    setSelectedCustomer(customer);
  }




  const onSubmit = async (option) => {
    const formData = getValues();
    await myPromiseModal({
      title: "Create New Return?", body: `A new Return Record will be created!`,
      showInput: false, placeHolder: '',
  }).then((res) => {
    if(res.decision === true){
      if(getEditReturn?.Ref_No){
        rewardService.updateReturn(formData).then((response)=> {
          const data =  logicContext.httpResponseManager(response.data[0]);
          console.log('success_update', response.data);
          if(data?.message_id == 1){
            logicContext.showToast(data?.message_text, 'success');
           
            navigate(-1);
          }
          
          
        });


      }else{
        rewardService.createNewReturn(formData).then((response)=> {
          console.log('success_new', response.data);
          const data =  logicContext.httpResponseManager(response.data[0]);
          if(data?.message_id == 1){
            logicContext.showToast(data?.message_text, 'success');
            navigate(-1);
          }
        });

      }
   
    }

  });

    //  validate

  };




  function returnDate() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (getEditReturn?.Return_Date_String !== undefined) {
      defaultDate = moment(getEditReturn?.Return_Date_String).format("YYYY-MM-DD");
    }

    return (
      <div className="">
      <input
      defaultValue={defaultDate}
      key={defaultDate}
        className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
        type="date"
        {...register("return_date", {
          required: "Return date is required",
        })}
      />
        <br />
                <small className="text-red-500">
                    {Boolean(errors["return_date"]?.message) &&
                        String(errors["return_date"]?.message)}
                </small>
    </div>

    );
  }

 


  return (
    <>
          <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="font-bold text-left mt-3 p-3" >New Carry Bag Return</h2>
            <section className="container max-auto p-5 border-2 mt-5 ">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <span className="text-sm  ">Customer Name  <small>(search)</small> </span>
                  <span className="text-danger font-bold" >*</span> 
                  <Grid container>
                    <Grid item xs={12}>
                      <CarryBagAddCustomer    {...register("customer_id", {
          required: "Customer is required is required",})} 
           defaultVal={{label: getEditReturn?.Customer_Full_Name}} key={getEditReturn?.Customer_Full_Name}  onCustomerSelect={onCustomerSelect} />
            <br />
                <small className="text-red-500">
                    {Boolean(errors["customer_id"]?.message) &&
                        String(errors["customer_id"]?.message)}
                </small>
                    </Grid>
                  </Grid>
                 
                 {selectedCustomer?.Name  &&  
                 
                 <ul class="list-group mt-5 p-4">
                 <h2 className="h4 text-left font-bold" >Customer Details</h2>

                 <li class="list pb-3"> Customer Name <span className="font-bold ml-4"  > {selectedCustomer?.Name} </span> </li>
                 <li class="list pb-3"> Customer Email <span className="font-bold ml-4"  > {selectedCustomer?.Email_Address} </span> </li>
                 <li class="list pb-3"> Mobile Number <span className="font-bold ml-4"  > {selectedCustomer?.Mobile} </span> </li>
            
                 </ul>

                 }
                 
                </Grid>


                <Grid item xs={6}>
                  <div className="mb-4">
                    <span className="text-sm ">Return Date</span>
                    <span className="text-danger font-bold" >*</span> 
                    {returnDate()}
                  </div>

                  <div className="">
                    <span className="text-sm ">Bag Quantity</span>
                    <span className="text-danger font-bold" >*</span> 
                    <div className="">
                      <input
                        className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        placeholder=""
                        autoFocus="autoFocus"
                        type="number"
                        {...register("return_quantity", {
                          required: "Return quantity is required",
                        })}
                        
                      />
                         <br />
                <small className="text-red-500">
                    {Boolean(errors["return_quantity"]?.message) &&
                        String(errors["return_quantity"]?.message)}
                </small>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                
                </Grid>


        


                <Grid item xs={12}>
                  <section>
                    <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    
                 
                      <div className='clearfix'>
                      <button
                          type="submit"
                          style={{ backgroundColor: "#476EF8" }}
                          className=" float-right text-sm text-white px-3 rounded py-2"
                        >
                          SAVE
                        </button>
                     
                       
                      
                      </div>
                    </div>
                  </section>
                </Grid>
              </Grid>
            </section>
          </form>
    </>
  );
}

export default CarrierBagAddNewPage;
