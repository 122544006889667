import { Dropdown } from "react-bootstrap";
import trash from "../../assets/trash.png";
import { Link, useLocation } from "react-router-dom";
import { itemsGroupService } from "../../services/itemGroup.service";
import LogicContext from "../../Context/LogicContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
// import '../pages/ItemPage.css'
import {
  SetTGroupItemList,GetGroupItemList, SetGroupBackup, GetGroupBackup, ResetGroupEdit} from "../../store/itemGroupAtom";
import underscore from "underscore";
import ItemGroupTable from "../ItemGroupTable";
import Tooltip from '@mui/material/Tooltip';
import RestartAlt from '@mui/icons-material/RestartAlt';

function ItemGroupPage() {
  const logicContext = useContext(LogicContext);
  const setItemGroupList  =  SetTGroupItemList();
const setGroupBackUp  =  SetGroupBackup();
const itemGroupBackup  = GetGroupBackup();
const groupList = GetGroupItemList();
const resetItemToEdit = ResetGroupEdit();
    

  const [itemSelect, setItemSelect] = useState([]);


  useEffect(()=> {
    if(groupList?.length < 1){
      itemsGroupService.getGroupItemsList().then(res => {
        if(res.data.length > 0){
            setItemGroupList(res?.data);
            setGroupBackUp(res?.data);
        }
    });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[groupList ]);



function loadItemsFromServer(){
  itemsGroupService.getGroupItemsList().then(res => {
    if(res.data.length > 0){
        setItemGroupList(res?.data);
        setGroupBackUp(res?.data);
    }
});
}
  

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];




  function customFilter(value) {
    if (value === "All") return setItemGroupList(itemGroupBackup);

    if (value === "Item_Id") {
      var temp = underscore.sortBy(groupList, value);
      var temp2 = temp.reverse();
      setItemGroupList(temp2);
    } else {
      var customFilter = underscore.sortBy(groupList, value);
      setItemGroupList(customFilter);
    }
  }


  function customFilterByStatus(status) {
    var customFilter = itemGroupBackup.filter(
      (item) => item.Item_Group_Status_Name === status
    );
    setItemGroupList(customFilter);
  }


  function filterItemReturnable(val) {
    var customFilter = itemGroupBackup.filter(
      (item) => item.Item_Group_Returnable_Name === val
    );
    setItemGroupList(customFilter);
  }


  function filterIventoryItems(status) {
    // var customFilter = itemMasterList.filter(
    //   (item) => item.Item_Tracking_Need_Name === status
    // );
    // setItemListState(customFilter);
  }


  function selectedItems(itemList) {
    setItemSelect(itemList);
  }


  function setItemToActive() {
    var xmlString = "";
    itemSelect?.map((item) => {
      xmlString += `<a><refno>${item.Item_Group_Id} </refno></a>`;
    });

    itemsGroupService.setGroupItemActive(`<root> ${xmlString} </root>`).then((res) => {
        logicContext.showToast(res.data[0]['message_text'], 'success');
        loadItemsFromServer();
      })
      .catch((err) => console.log("err ", err));
  }


  function setItemToInActive() {
    var xmlString = "";
    itemSelect?.map((item) => { xmlString += `<a><refno>${item.Item_Group_Id} </refno></a>`;
    });

    itemsGroupService.setGroupItemInActive(`<root> ${xmlString} </root>`).then((res) => {
        logicContext.showToast(res.data[0]['message_text'], 'success');
        loadItemsFromServer();
      })
      .catch((err) => console.log("err ", err));
  }


  function deleteItems() {
    // var xmlString = "";
    // itemSelect?.map((item) => {xmlString += `<a><refno>${item.Item_Id} </refno></a>`;
    // });

    // itemsService.deleteItems(`<root> ${xmlString} </root>`)
    //   .then((res) => {
    //     loadItemsFromServer();
    //   })
    //   .catch((err) => console.log("err ", err));
  }





  return (
      <>
       <div className=" b">
      <section className="">
        <section className=" py-4 px-2    md:flex justify-between ">
     
     <div className="flex">
     <div className="flex mt-1  rounded bg-filterColor pr-4 -ml-2 ">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "#E5E5E5",
                  border: "none",
                  color: "#45444B",
                  fontSize: "12px",
                  height: "35px",
                }}
                id="dropdown-basic"
              >
                FILTER BY
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => customFilter("All")}>
                  All
                </Dropdown.Item>

                <Dropdown.Item onClick={() => customFilterByStatus("Active")}>
                  Active Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => customFilterByStatus("Inactive")}>
                  InActive Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => filterIventoryItems("Yes")}>
                  Inventory Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => filterIventoryItems("No")}>
                  Non Inventory Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => filterItemReturnable("No")}>
                  Non Returnable Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => filterItemReturnable("Yes")}>
                  Returnable Items
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div>

            <Tooltip title="Reload" >
            <RestartAlt className=" cursor ml-2 mt-1" onClick={()=> loadItemsFromServer()} color="primary" fontSize="large" />
          </Tooltip>
     </div> 
       

          <div className="flex  ">
            {itemSelect.length > 0 ? (
              <div className="flex ">
                <button
                  onClick={() => setItemToActive()}
                  className=" bg-filterColor rounded text-black  text-xs py-[1.8vh] font-[Poppins]  mt-1 mr-1 px-4"
                >
                  ACTIVE
                </button>

                <button
                  onClick={() => setItemToInActive()}
                  className="bg-filterColor mx-2  rounded text-black text-xs py-[1.8vh] font-[Poppins] mt-1  px-4"
                >
                  INACTIVE
                </button>

                <img
                  onClick={() => deleteItems()}
                  className=" bg-filterColor mr-2   px-4 py-[1.8vh] mt-1   rounded text-black"
                  src={trash}
                  alt=""
                />

                {/* <button className=" bg-filterColor rounded text-black text-sm font-[Poppins] py-2 mt-1  px-3">
                  Import
                </button>

                <button className="bg-filterColor mx-2 rounded text-black text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3">
                  Export
                </button> */}
              </div>
            ) : null}

            <Link
              to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-item-group`}
            >
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                ADD NEW
              </button>
            </Link>
          </div>
        </section>

        {/*  Table here... */}
        <ItemGroupTable selectedItems={selectedItems}></ItemGroupTable>
      </section>
    </div>
      </>
  
  );
}

export default ItemGroupPage;
