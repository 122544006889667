import { useEffect } from "react";

function ItemImageDisplay({imageList}) {


    useEffect(()=> {

        console.log('imageList ', imageList);

    },[imageList?.length]);


    return ( 
        <>

<div class="container mx-auto py-8">
  <div class="mb-4">
    <img src={imageList[0]?.Image_URL} alt="image_1" class=" h-64 object-cover rounded-lg shadow-md" />
  </div>
  <div class="grid grid-cols-2 sm:grid-cols-4 gap-4">
    <div>
      <img src={imageList[1]?.Image_URL} alt="image_2" class="w-full h-32 object-cover rounded-lg shadow-md" />
    </div>
    <div>
      <img src={imageList[2]?.Image_URL} alt="image_3" class="w-full h-32 object-cover rounded-lg shadow-md" />
    </div>
    <div>
      <img src={imageList[3]?.Image_URL} alt="image_4" class="w-full h-32 object-cover rounded-lg shadow-md"/>
    </div>
    <div>
      <img src={imageList[4]?.Image_URL} alt="image_5" class="w-full h-32 object-cover rounded-lg shadow-md"/>
    </div>
  </div>
</div>
        
        </>
     );
}

export default ItemImageDisplay;