import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableContainer } from "@mui/material";
import select from "../assets/selectarrow.png";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import React from "react";
import Paper from "@mui/material/Paper";
import {itemsService} from '../services/items.service';
import { useEffect, useRef, useState } from "react";
import underscore from "underscore";
import up from "../assets/icons/Caret_up.png";
import down from "../assets/icons/Caret_down.png";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../store/transAtom";
import "../components/ItemT.css";
import { GetItemTransactionValue, SetItemTransaction } from "../store/itemAtom";
import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}




const tableHeader = [
  { field: 'Order_Date', headerName:'Date' , width: 140 , headerClassName: "tableHeader "},
   { field: 'Order_No', headerName:'Order No' , width: 140 , headerClassName: "tableHeader "},
   { field: 'Customer_Name', headerName:' Customer Name' , width: 140, headerClassName: "tableHeader" },
   { field: 'Sales_Quantity', headerName:'  Qty Sold' , width: 140, headerClassName: "tableHeader" },
   { field: 'Sales_Rate', headerName:' Price' , width: 140, headerClassName: "tableHeader"},
   { field: 'Total_Amount', headerName:' Total' , width: 140, headerClassName: "tableHeader"},
   { field: 'Order_Status_Name', headerName:' Status' , width: 140, headerClassName: "tableHeader"},
  ]


function ItemTransaction(props) {

  const transList = GetItemTransactionValue();
  const setTrans = SetItemTransaction();


  useEffect(() => {
    console.log('FIRE')
    itemsService.getItemTransaction(props.item_id).then(res => {
      console.log('RESSS ', res.data)
      const historyList  =  res.data?.map((item, i)=> {
        return ({...item, id:i})
       });

       if(historyList.length){
         setTrans(historyList);
      }else{
        setTrans([]);

      }
    })
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item_id]);





  return (
    <div
      className="tab-pane fade show active"
      id="tabs-home"
      role="tabpanel"
      aria-labelledby="tabs-home-tab" >
      <section>
        <div className="flex filter_by mt-3 justify-between">
             {/* SEARCH BAR */}
        <div className="flex justify-start  ">
          <div className="lg:w-[25vw] ">
            <div className="flex    border-2 rounded ">
              <button className="flex  bg-inputColor items-center justify-center px-4  ">
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="bg-inputColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.4996 10.875C16.4996 11.9875 16.1697 13.0751 15.5516 14.0001C14.9335 14.9251 14.055 15.6461 13.0272 16.0718C11.9994 16.4976 10.8684 16.609 9.77723 16.3919C8.68608 16.1749 7.6838 15.6391 6.89713 14.8525C6.11046 14.0658 5.57473 13.0635 5.35769 11.9724C5.14065 10.8812 5.25204 9.75024 5.67779 8.72241C6.10353 7.69457 6.8245 6.81607 7.74953 6.19798C8.67455 5.5799 9.76209 5.25 10.8746 5.25C12.3659 5.25169 13.7957 5.84487 14.8502 6.89939C15.9047 7.95392 16.4979 9.38368 16.4996 10.875ZM21.5294 21.5304C21.3888 21.6711 21.198 21.7501 20.9991 21.7501C20.8001 21.7501 20.6094 21.6711 20.4687 21.5304L16.4165 17.4783C14.7122 18.9092 12.5213 19.6269 10.3006 19.4817C8.08 19.3366 6.00111 18.3398 4.49749 16.6993C2.99387 15.0587 2.18161 12.9011 2.23008 10.6762C2.27855 8.45139 3.18402 6.33114 4.75766 4.75763C6.3313 3.18413 8.45163 2.27884 10.6765 2.23057C12.9013 2.18229 15.0589 2.99474 16.6993 4.4985C18.3398 6.00226 19.3363 8.08123 19.4813 10.3019C19.6262 12.5225 18.9083 14.7134 17.4773 16.4176L21.5294 20.4698C21.6701 20.6104 21.7491 20.8012 21.7491 21.0001C21.7491 21.199 21.6701 21.3898 21.5294 21.5304ZM10.8746 18C12.2838 18 13.6613 17.5821 14.833 16.7992C16.0047 16.0163 16.918 14.9035 17.4573 13.6016C17.9965 12.2997 18.1376 10.8671 17.8627 9.48498C17.5878 8.10287 16.9092 6.83331 15.9127 5.83686C14.9163 4.84042 13.6467 4.16182 12.2646 3.8869C10.8825 3.61199 9.44991 3.75308 8.14799 4.29236C6.84607 4.83163 5.73329 5.74486 4.95039 6.91656C4.16748 8.08826 3.74961 9.46581 3.74961 10.875C3.75175 12.764 4.5031 14.575 5.83883 15.9108C7.17457 17.2465 8.9856 17.9979 10.8746 18Z"
                    fill="#45444B"
                  />
                </svg>
              </button>
              <input
           
                className="px-4 text-sm bg-inputColor  py-2 h-[5vh] w-100"
                placeholder="Search "
              />
            </div>
          </div>
        </div>


          <div className="">
            <label className="mr-5" htmlFor="">
              <label >Filter by:</label> 
              <select style={{ color: "#476EF8" }} name="cars" id="cars">
                <optgroup>
                  <option  value="volvo">
                    Sales Order
                  </option>
                </optgroup>
              </select>
            </label>

            <label htmlFor="">
              <label>Status:</label>
              <select style={{ color: "#476EF8" }}  className=".filter_by" name="cars" id="cars">
                <optgroup  >
                  <option  value="volvo">
                    All
                  </option>
                
                </optgroup>
              </select>
            </label>
          </div>
        </div>

        {/* TABLE */}
        <div className="mt-7" style={{ height: 250, width: '100%' }}>
      <DataGrid
        columns={tableHeader}
        rows={transList}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>

      </section>
    </div>
  );
}

export default ItemTransaction;
