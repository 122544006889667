

import { TableBody, TableCell } from "@material-ui/core";
import { TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import {itemsService} from '../services/items.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function ItemStockInformation(props) {
  const [stockList, setStockList] = useState([])


  useEffect(()=> {
    showStockInformation();
  },[props.Item_Id]);


  function showStockInformation(){
    // if(stockList.length < 1){
      itemsService.getItemStockInformation(props.Item_Id).then( res => {
        if( res?.data !== undefined){
          setStockList(res.data);
        }
  
      });
    // }

  }


  function stockAtHand() {
    let total = 0;
    stockList.map((element)=> {
        total += element.Stock_In_Hand;
    });
    return total;
  }

  function committedStock() {
    let total = 0;
    stockList.map((element)=> {
        total += element.Commited_Stock;
    });
    return total;
  }

  function availableStock() {
    let total = 0;
    stockList.map((element)=> {
        total += element.Available_For_Sale;
    });
    return total;
  }

  function stockPrice() {
    let total = 0;
    stockList.map((element)=> {
        total += element.Stock_Price;
    });
    return total;
  }



    return ( 
        <>

      <section className="border mt-2 py-1 ">
      <Accordion className="mx-4 ">
        <AccordionSummary onClick={showStockInformation}
        style={{ backgroundColor: "#F1F1F1" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>View Stock Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <section className=" mt-2 right-1 border-1   md:  " style={{}}>
        {/* STOCK INFORMATION  */}
        <section className="mx-4">
          <section className="flex mt-3 stock_information justify-between">
            <span className="text-sm mt-2 font-bold">Stock Information</span>

            <div>
              {" "}
              <button className="btn text-sm succes">Accounting Stock</button>
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="text-white text-sm btn"
              >
                Physical Stock
              </button>
            </div>
          </section>
        </section>
        <section className=" mx-3">
          <TableContainer className="mt-3 table_parent ">
            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F1F1F1", border: "0" }}>
                <TableRow>
                  <TableCell align="left">
                    <div className="flex">
                      <span>Warehouse</span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="flex">
                      <span>Stock in Hand</span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="flex">
                      <span>Committed Stock</span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="flex">
                      <span>Available for Sale</span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="flex">
                      <span>Stock Price</span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockList.map((stock, i)=> {
                  return (

                    <TableRow key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left">
                      <span> {stock.Warehouse_Name} </span>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span>{formatCustomNumber(stock.Stock_In_Hand)} </span>{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span>{formatCustomNumber(stock.Commited_Stock)} </span>{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span>{ formatCustomNumber(stock.Available_For_Sale)} </span>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span>{formatCustomNumber(stock.Stock_Price)} </span>{" "}
                    </TableCell>
                  </TableRow>
                  )
                })}


                  <TableRow 
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left">
                      <span className="font-weight-bold"> Grand Total </span>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span className="font-weight-bold">{formatCustomNumber(stockAtHand())}  </span>{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span className="font-weight-bold">{formatCustomNumber(committedStock())} </span>{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span className="font-weight-bold">{formatCustomNumber(availableStock())} </span>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <span className="font-weight-bold">{formatCustomNumber(stockPrice())} </span>{" "}
                    </TableCell>
                  </TableRow>
             
              
              </TableBody>
            </Table>
          </TableContainer>
        </section>
      </section>
          </Typography>
        </AccordionDetails>
      </Accordion>
      </section>


        </>
     );
}

export default ItemStockInformation;