
import { useEffect } from "react";
import { GetChartEntryMasterData, SetChartEntryMasterData} from "../../store/chartOfAccountAtom";
import {accountService}  from '../../services/accountService';


function ChartOfAccountCurrency(props) {
  const masterData = GetChartEntryMasterData();
  const getMasterData = GetChartEntryMasterData();
  const setMasterData = SetChartEntryMasterData();


  useEffect(()=> {
    if(getMasterData['Account_Main'] ===  undefined){
        accountService.getChartEntryMasterData().then(response => {
          if(response?.data !== undefined){
            setMasterData(response.data);
          }
        });
      }

  },[getMasterData, setMasterData])


  const  createSelectItems = ()=> {
    let items = [];         
    masterData?.Currency?.forEach((item, i)=> {
        if(item.IsDeafult_Currency === true){
          props.onCurrencySelect(item.Currency_Id)
        }
      items.push(<option key={i} value={item.Currency_Id}>{item.Currency_Name_Short}</option>)
    });

    
    return items;
} 




    const handleChange = (value) => {
      props.onCurrencySelect(value);
      };


    return ( 
        <>
        <select style={{backgroundColor:'#F1F1F1',  width: '34em'}} className="w-full h-[60px] text-sm "
         onChange={(ev)=> handleChange(ev.target.value)}>
          {createSelectItems()}
  </select>



        </>
     );
}

export default ChartOfAccountCurrency;
