
import { useState } from "react";
import Select from 'react-select';
import { GetChartEntryMasterData} from "../../store/chartOfAccountAtom";
import underscore from "underscore";


const createGroup = (groupName, options, setValue) => {
  return {
    label: (() => {
      return (
        <div
        >
          {groupName}
        </div>
      );
    })(),
    options: options
  };
};


function ChartOfAccountTypeSelect(props) {
  const masterData = GetChartEntryMasterData();


 const groupList =  underscore.groupBy(masterData['Account_Type'], 'Account_Main_Name');

  let options = [];
  for (const [key, list] of Object.entries(groupList)) {
    options.push(createGroup(key, list.map((item)=>
     {return ({...item, label: item.Account_Type_Name})} )));
  }


    const customStyles = {
        control: base => ({
          ...base,
          height: 54,
          backgroundColor:'#F1F1F1',
          width:480,
        }),
        indicatorSeparator: styles => ({ ...styles, display : 'none' }),
        placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
      }



    const handleChange = (value) => {
      props.onAccountTypeSelect(value)
      };


    return ( 
        <>
   
         <Select defaultValue={props?.defaultVal}
           styles={customStyles} onChange={handleChange}
           options={options}
           />
        </>
     );
}

export default ChartOfAccountTypeSelect;
