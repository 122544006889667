
import { useEffect } from "react";

import { itemsService } from "../../services/items.service";
import { SetTItemList, GetItemList, SettItemBackupList,GetItemBackupList,
  ResetItemToEdit
} from "../../store/itemAtom";
import { useParams } from "react-router-dom";

import { twMerge } from "tailwind-merge";




function ItemDetailsSideScroll(props) {
  const setItemListState = SetTItemList();
  const setItemBackupList = SettItemBackupList();
  const itemList = GetItemList();
  const itemMasterList = GetItemBackupList();
const {  item_id } = useParams();


  useEffect(()=> {
    if(itemList.length < 1){
      itemsService.getItemsList().then((res) => {
        if(res?.data !== undefined){
          setItemListState(res?.data);
          setItemBackupList(res?.data);
        }
       
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemList.length]);





    

  function customFilter(e) {
    const value = e.target.value;
    if (value == 0) {
      return setItemListState(itemMasterList)
    }

   if(value == 1){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Variety_Id === 2
    );
   return setItemListState(customFilter);
   }

   if(value == 2){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Variety_Id === 1
    );
   return setItemListState(customFilter);
   }

   if(value == 3){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Category_Id === 1
    );
    return setItemListState(customFilter);
   }

   if(value == 4){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Category_Id === 2
    );
    return setItemListState(customFilter);
   }
 
  }

  console.log('IDDD ', item_id);

  return (
    <>
        
    <section  className=" scroll   z-0 mt-3	main border-1 h-[100vh]"
    onChange={customFilter}>
      {/* <p className="ml-4 mt-4 text-xs font-bold md:text-sm ">Items Information</p> */}
      <div>
            <button className="md:mr-3 w-120 ">
              {" "}
              <select
                style={{
                  border: "none",
                  borderRadius:"5px",
                  backgroundColor: "#E5E5E5",
                  color: "#45444B",
                }}
                
                className=" mb-4 text-xs">
                <option value={0} className="bg-filterColor">
                  All
                </option>
                <option value={1} className="bg-filterColor">
                  Composite Items
                </option>
                <option value={2} className="bg-filterColor">
                SIngle Items
                </option>
                <option value={3} className="bg-filterColor">
                Raw Materials
                </option>
                <option value={4} className="bg-filterColor">
                Packing Materials
                </option>
              </select>
            </button>
          </div>


      {itemList.map((item, i) => {
        return (
          <div 
          className={twMerge(
             `show-cursor border-2 p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
              Number(item_id) === item.Item_Id
                ? "bg-gray-200"
                : "hover:bg-gray-100"
            }`
          )}
          key={i}>
            <label className="ml-1">
              {/* <input type="checkbox" className="w-[2vh] h-[2vh]" /> */}
            </label>
            <label onClick={()=> props.viewItemDetails(item.Item_Id)} className="ml-2 ">
              <span className="scroll_text ml-1 md:text-sm">
               {item.Item_Name}
              </span> | <span>{item?.Item_Type_Name} </span>
               <br/>
              <small className="ml-3 text-primary" >{item?.Item_Variety_Name} </small>
            </label>
          </div>
        );
      })}
    </section>
    </>
  )
}

export default ItemDetailsSideScroll