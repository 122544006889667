
import { useEffect, useState } from "react";

import { GetInvoiceList, GetInvoiceFilter } from "../../store/invoiceAtom";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { twMerge } from "tailwind-merge";
// import CreditNoteFilter from "./CreditNoteFilter";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { GetProductSharingAtom, SetProductSharingAtom } from "../../store/productSharingAtom";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
import { productSharingService } from "../../services/productSharing.service";



function ProductSharingScroll() {
    const productSharingAtom = GetProductSharingAtom();
    const setProductSharingAtom = SetProductSharingAtom();
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const {  id } = useParams();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(() => {
    setTableData(productSharingAtom.product_sharing_info);
  }, [productSharingAtom]);

//   const onFilterSelect = (selection) => {
//     if(selection == 0){
//        setTableData(creditNoteAtom?.credit_note);
//        return;
//     }
//     const newList =  creditNoteAtom?.credit_note?.filter((item)=> item?.Status_Id == selection);
//     if(newList?.length){
//       setTableData(newList);
//     }else{
//       setTableData([]);
//     }
//   };


useEffect(()=> {
if(productSharingAtom.product_sharing_info?.length < 1){
    loadVoucherData();
}
},[productSharingAtom.product_sharing_info?.length]);

  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
  }


  const loadVoucherData = () => {
    productSharingService.productSharingList().then(res => {
      console.log('LISSD ',  res.data);
      if(res.data){
          setProductSharingAtom(res.data);
         
      }
  
    });
  }


  const manageInvoiceStatus = (statusCode) => {
    let defaultClass = "text-primary";

    switch (statusCode) {
      case 1:
        defaultClass = "text-danger";
        break;
      case 2:
        defaultClass = "text-success";
        break;
      case 3:
        defaultClass = "text-warning";
        break;
      case 4:
        defaultClass = "text-info";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-medium ${defaultClass} `}>{statusCode}</span>
    );
  };

  return (
    <>
      <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        <div className="border-b-[1px] border-b-gray-300">
          {/* <CreditNoteFilter
            bulkActionData={creditNoteAtom?.status_info}
            onFilterSelect={onFilterSelect}
          /> */}
        </div>
        {tableData.map((share, i) => {
          return (
            <div
              className={`p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                id == share?.Ref_No
                  ? "bg-gray-200"
                  : "hover:bg-gray-100"
              }`}
            >
              <div className="flex flex-row">
                <div className="">
                  {/* <input
                    onChange={(e) => onCheckAll(e, i)}
                    checked={note?.checked ? note?.checked : ""}
                    type="checkbox"
                    className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
                  /> */}
                </div>

                <div
                  onClick={() => viewItemDetails(share.Ref_No)}
                  className="ml-1"
                >
                  <div className="text-sm font-bold text-gray-700">
                    {share?.Sharing_Name}
                  </div>
                  {/* <div className="text-xs text-gray-400 font-semibold italic">
                    {note?.Invoice_Ref_No}
                  </div> */}
                  <div className="text-sm lg:text-xs text-gray-600">
                    {formatCustomDate(share.Created_Date)}
                  </div>
                </div>
              </div>
              <div
                onClick={() => viewItemDetails(share.Ref_No)}
                className="lg:mt-2 lg:ml-5 "
              >
                <div className={twMerge("font-bold font-normal")}>
                  Slot: {share.Total_Purchased_Slot}/{share.Total_Remaining_Slot + share.Total_Purchased_Slot}
                </div>

                <div className="mt-[2px] text-sm lg:text-xs font-bold text-gray-700">
                  <CurrencyFormat
                    value={formatCustomNumber(share.Slot_Price)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                  
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default ProductSharingScroll;
