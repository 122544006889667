import { Grid } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import AddOnSubPay from "./AddOnSubPay";
import AddonSubTerm from "./AddOnSubTerm";
import AddSubCoupon from "./AddSubCoupon";
import AddSubCustomer from "./AddSubCustomer";
import AddsubProduct from "./AddsubProduct";
import AddSubtypeSelect from "./AddSubtypeSelect";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SetSubEntryMasterdata,
  GetSubEntryMasterdata,
} from "../../store/subscriptionAtom";
import { subscriptionService } from "../../services/subscription.service";
import underscore from "underscore";
import { useForm, useFormState } from "react-hook-form";
import LogicContext from "../../Context/LogicContext";
import AddSubscriptionadminSelect from "./AddSubscriptionAdminSelect";
import SubscriptionGiftSelect from "./SubscritionGiftSelect";
import AddSubGatewaySelect from "./AddSubGatecheckbox";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import AddonSubPartialSub from "./AddonSubPartialSub";
import { GetSubscriptionEdit, ResetSubscriptionEdit } from "../../store/subscriptionAtom";
import { GetEditSalesOrder, ResetEditSalesOrder } from "../../store/salesOrderAtom";
import AddSubPlanTable from "./AddSubPlanTable";
import AddSubAddonTable from "./AddSubAddonTable";
import '../../pages/Table.css';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useRef } from "react";
import AddSubscriptionTable from "./AddSubscriptionTable";
import SubscriptionCreateSubFromSalesOrder from "./SubscriptionCreateSubFromSalesOrder";



const initialAddonState =
{
  Addon_Minimim_Payment: 0,
  Addon_Price: 0,
  Quantity: 1,
  tax_id: 0
}



const AddSubscriptionPage = () => {
  const setEntryMasterdata = SetSubEntryMasterdata();
  const getEntryMasterdata = GetSubEntryMasterdata();
  const getSubscriptionEdit = GetSubscriptionEdit();

  const editableSalesOrder = GetEditSalesOrder();
  const resetSalesOrderEdit = ResetEditSalesOrder();

  const resetSubscriptionEdit = ResetSubscriptionEdit();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
  const { dirtyFields } = useFormState({ control });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const logicContext = useContext(LogicContext);
  const [giftPerson, setGiftperson] = useState({});
  const [gwayList, setGwayList] = useState([]);
  const [reOccurChecked, setReOccurChecked] = useState(true);
  const [createInvoiceChecked, setCreateInvoiceChecked] = useState(false);
  const [showCustomerDetails, setshowcustomerDetails] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [refreshCount, setrefreshCount] = useState(0);
  const [addonList, setAddonList] = useState([initialAddonState]);
  const [currentPlan, setCurrentPlan] = useState({
    Plan_Price: 0, Plan_Minimum_Payment: 0,
    Quantity: 0, tax_id: 0
  });
  const [currentCoupon, setCurrentCoupon] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const Customer_Id = searchParams.get('Customer_Id');
  const Customer_Full_Name = searchParams.get('Customer_Full_Name');
  const Sales_Order_Ref_No = searchParams.get('Sales_Order_Ref_No');


  console.log('Sub to Edit ', getSubscriptionEdit);
  // console.log('sub to Edit ', editableSalesOrder);
  setValue("Customer_Id", Customer_Id);




  useEffect(() => {
    document.title = 'Add Subscription';
   
    setValue("Recuring_Subscription_Id", 1);
    setValue("Gift_Id", 2);
    setValue("Payment_Enable_Partial_Id", 2);
    setValue("Email_Send_Allow_Id", 2);
    setValue("Sales_Order_Ref_No", Sales_Order_Ref_No);

    let isMounted = true

    if (isMounted) {
      if (getSubscriptionEdit?.details?.Subscription_Ref_No) {
        setGwayList(getSubscriptionEdit?.gateWayList);
        setValue("Subscription_Ref_No", getSubscriptionEdit.details?.Subscription_Ref_No);
        setValue("Recuring_Subscription_Id", getSubscriptionEdit.details?.Recuring_Subscription_Id);
        setValue("Gift_Id", getSubscriptionEdit.details?.Gift_Id);

        setValue("Customer_Id", getSubscriptionEdit?.details?.Customer_Id);
        setValue("Gift_Customer_Id", getSubscriptionEdit?.details?.Gift_Client_Id);
        setValue("Product_Id", getSubscriptionEdit?.plan?.Product_Id);

        setValue("Billing_Address", getSubscriptionEdit?.details?.Billing_Address1);
        setValue("Payment_Term_Id", getSubscriptionEdit?.details?.Payment_Term_Id);
        setValue("Shipping_Address", getSubscriptionEdit?.details?.Shipping_Address1);
        setValue("Customer_Email", getSubscriptionEdit?.details?.Customer_Email_Address);
        setValue("Subscription_Activated_Date", getSubscriptionEdit?.details?.Subscription_Activated_Date_String);
        setValue("Recuring_Subscription_Id", getSubscriptionEdit?.details?.Recuring_Subscription_Id);
        setValue("Payment_Enable_Partial_Id", getSubscriptionEdit?.details?.Payment_Enable_Partial_Id);
        setValue("Email_Send_Allow_Id", getSubscriptionEdit?.details?.Email_Send_Allow_Id)
        setValue("Subscription_Ref_No", getSubscriptionEdit?.details?.Subscription_Ref_No);
        setValue("Customer_Id", getSubscriptionEdit?.details?.Customer_Id);
        setValue("Billing_Address", getSubscriptionEdit?.details?.Billing_Address1);
        setValue("Shipping_Address", getSubscriptionEdit?.details?.Shipping_Address1);
        setValue("Customer_Email", getSubscriptionEdit?.details?.Customer_Email_Address);

        if (getSubscriptionEdit?.details?.Recuring_Subscription_Id === 2) {
          setValue("Subscription_Expire_Cycle_No", getSubscriptionEdit?.details?.Subscription_Expire_Cycle_No);

        }

        setCurrentPlan(getSubscriptionEdit?.plan)
        setCurrentCoupon(getSubscriptionEdit?.coupon)
        setAddonList(getSubscriptionEdit?.addonList);
        if (getSubscriptionEdit.details?.Gift_Id === 1) {
          setshowcustomerDetails(true);
          const giftPerson = {
            Customer_Full_Name: getSubscriptionEdit?.details?.Gift_Person_Name,
            Customer_Mobile_No: getSubscriptionEdit?.details?.Gift_Person_Mobile_Number,
            Customer_Email_Address: getSubscriptionEdit?.details?.Gift_Person_Email_Address,
            Shipping_Address1: getSubscriptionEdit?.details?.Gift_Person_Address
              + ' ,' + getSubscriptionEdit?.details?.Gift_Person_Area_Name + ' ,'
              + ' , ' + getSubscriptionEdit?.details?.Gift_Person_Area_Name
              + ' ' + getSubscriptionEdit?.details?.Gift_Person_City_Name + ',' + getSubscriptionEdit?.details?.Gift_Person_Country_Name
          }
          setGiftperson(giftPerson);


          if (getSubscriptionEdit?.details?.Recuring_Subscription_Id == 1) {
            setReOccurChecked(true);
          } else {
            setReOccurChecked(false);
          }
        }
      }
    }

    //  REMOVE THIS WHEN YOU ARE DONE 
    return () => {
      resetSubscriptionEdit();
      resetSalesOrderEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    subscriptionService.getEntryMasterData().then((res) => {
      if (res.data !== undefined) {
        setEntryMasterdata(res.data);
      }
    });
  }, []);



  useEffect(() => {

    if (Customer_Id) {
      subscriptionService.getCustomer(Customer_Id).then(res => {
        console.log('customer ', res.data)
        if (res?.data !== undefined) {
          setValue("Customer_Id", res.data.Customer_Id);
          setValue("Billing_Address", res.data?.Billing_Address1);
          setValue("Shipping_Address", res.data.Shipping_Address1);
          setValue("Customer_Email", res.data.Customer_Email_Address);
          setrefreshCount(prev => {
            return (prev + 1)
          });
        }

      })
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Customer_Id]);



  useEffect(() => {
    // help  show customer when it change
  }, [refreshCount])



  function onCustomerSelect(val) {
    setValue("Customer_Id", val.Customer_Id);
    setValue("Billing_Address", val.Billing_Address1);
    setValue("Shipping_Address", val.Shipping_Address1);
    setValue("Customer_Email", val.Customer_Email_Address);
    setrefreshCount(prev => {
      return (prev + 1)
    });

  }


  function onEmailSendSelect(val) {
    setValue("Email_Send_Allow_Id", val);
  }



  function onGiftUserSelect(customer) {
    setValue('Gift_Customer_Id', customer.Customer_Id);
    setGiftperson(customer);

  }


  const onSubmit = async () => {
    const data = getValues();
    console.log('this ', data)
    const uniqList = await underscore.uniq(addonList, false, (item) => {
      return item?.Addon_Id;
    });


    if (!data?.Customer_Id) {
      return logicContext.showToast('Please select a customer!', 'error')
    }

    await myPromiseModal({
      title: "Confirmation", body: " Do you want to save this record?",
      showInput: false, placeHolder: ''
    }).then((res) => {
      if (res.decision === true) {

    // saving subscription from sales order
        if(editableSalesOrder?.Sales_Order_Ref_No){
          const data = getValues();
          subscriptionService.submitSalesOrderSubscription({...data}).then(res => {
            if (res?.data !== undefined) {
              const { message_id, message_text } = res.data[0];
              if (message_id == 1) {
                logicContext.showToast(message_text, "success");
                navigate(-1);
              } else {
                logicContext.showToast(message_text, "error");
              }
            }

          })

        }else{
          //  creating new subscription
          if (!currentPlan?.Plan_Id) {
            return logicContext.showToast('Please select a plan', 'error')
          }
      
          if (addonList?.length !== uniqList.length) {
            return logicContext.showToast('Multiple Addon selection, Addon must be unique!', 'error')
          }
      
          
          let xml_string_product = "";
          let allProduct = "";
  
          let xmlplan = `<a> <type_id>${1} </type_id>
                    <plan_id>${currentPlan?.Plan_Id} </plan_id>
                    <minimum_amount>${currentPlan?.Plan_Minimum_Payment ? currentPlan?.Plan_Minimum_Payment : 0} </minimum_amount>
                    <rate>${currentPlan?.Plan_Price ? currentPlan?.Plan_Price : 0} </rate>
                    <quantity>${currentPlan?.Quantity ? currentPlan?.Quantity : 0} </quantity>
                    <tax_id>${currentPlan?.tax_id ? currentPlan?.tax_id : 0} </tax_id>
                      </a>`;
  
          var xmlAddon = "";
          var tempString = "";
          addonList?.forEach((addon) => {
            if (addon.Addon_Id !== undefined) {
              tempString = `<a> <type_id>${2} </type_id>
                          <plan_id>${addon?.Addon_Id} </plan_id>
                          <minimum_amount>${addon?.Addon_Minimim_Payment ? addon?.Addon_Minimim_Payment : 0} </minimum_amount>
                          <rate>${addon?.Addon_Price ? addon?.Addon_Price : 0} </rate>
                          <quantity>${addon?.Quantity ? addon?.Quantity : 0} </quantity>
                          <tax_id>${addon?.tax_id ? addon?.tax_id : 0} </tax_id>
                            </a>` ;
              xmlAddon += tempString;
            }
          });
  
  
  
  
          let payGateWay = gwayList?.map((pay) => {
            return (
              `<a><gateway_id> ${pay.Payment_Gateway_Id} </gateway_id> </a>`
            )
          });
  
          let xmlcoupon = `<a> <type_id>${3} </type_id>
                    <plan_id>${currentCoupon?.Coupon_Id ? currentCoupon?.Coupon_Id : 0} </plan_id>
                    <minimum_amount>${0} </minimum_amount>
                    <rate>${0} </rate>
                    <quantity>${0} </quantity>
                    <tax_id>${0} </tax_id>
                    </a>
  
                    `;
          allProduct += xmlplan;
  
          if (addonList.length > 0) {
            allProduct += xmlAddon
          }
          if (currentCoupon?.Coupon_Id) {
            allProduct += xmlcoupon;
          }
  
          xml_string_product = `<root>${allProduct} </root>`;
          let option = 1;
          const data = getValues();
          if (getSubscriptionEdit?.details?.Subscription_Ref_No) {
            option = 2
          }
  
          if (payGateWay.length > 0) {
            var xml_string_gateway = `<root> ${payGateWay} </root>`
          }
  
          subscriptionService.submitSubscription({
            ...data, option: option,
            xml_string_product: xml_string_product, xml_string_gateway: xml_string_gateway
          }).then((res) => {
            if (res?.data !== undefined) {
              const { message_id, message_text } = res.data[0];
              if (message_id == 1) {
                logicContext.showToast(message_text, "success");
                navigate(-1);
              } else {
                logicContext.showToast(message_text, "error");
              }
            }
          }).catch(err => {
            logicContext.showToast('form submission failed!', "error");
          });

        }
     
      }
    });
  };


  useEffect(() => {
    // just to refresh the components
  }, [startDate]);


  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("Form error", "error");
  }


  function onReOccurrChange(e) {
    const val = e.target.checked;

    if (val == true) {
      setReOccurChecked(val);
      setValue("Recuring_Subscription_Id", 1);
    } else {
      setReOccurChecked(val);
      setValue("Recuring_Subscription_Id", 2);
    }
  }


  const onStartDateChange = (e) => {
    const newDate = e.target.value;
    setStartDate(new Date(newDate));
    console.log('se ', newDate)
    setValue('Subscription_Activated_Date', newDate);
  }

  function onCreateInvoiceChange(e) {
    const val = e.target.checked;

    if (val == true) {
      setCreateInvoiceChecked(val);
      setValue("Current_Billing_Cycle_Invoice_Id", 1);
    } else {
      setCreateInvoiceChecked(val);
      setValue("Current_Billing_Cycle_Invoice_Id", 2);
    }
  }



  function onGiftChecked(val) {
    setValue("Gift_Id", val);
    if (val === 2) {
      setshowcustomerDetails(false);
      setValue("Gift_Person_Name", "");
      setValue("Gift_Person_Address", "");
      setValue("Gift_Person_Mobile_Number");
      setValue("Gift_Person_Email_Address", "");
      setGiftperson({});
    }
    if (val === 1) {
      setshowcustomerDetails(true);
    }
  }

  function onGatewaySelect(listOfSelections) {
    // setValue("Payment_Gateway_Type_Id", val);
    setGwayList(listOfSelections)
  }

  function onPaymentTermsSelect(val) {
    setValue("Payment_Term_Id", val.Payment_Term_ID);
  }

  function allowPartial(val) {
    setValue("Payment_Enable_Partial_Id", val);
  }


  function onAddonNameSelect(newAddon, index) {
    console.log('NEW Addon',newAddon );
    const newList = addonList.map((item, i) => {
      if (i === index) {
        return {...item,Addon_Id: newAddon.Item_Id,label:newAddon.Item_Name ,
        Addon_Minimim_Payment:0,Addon_Price: newAddon.Sales_Rate, Quantity: newAddon.Quantity ,
         tax_id:0 }
      } else {
        return (item)
      }
    });

    setAddonList((prev) => {
      return ([...newList]);
    })
  }


  function onPlanNameSelect(val) {
    setCurrentPlan({ ...val, tax_id: 0 });
  }


  function onPlanAmountChange(e) {
    const new_amount = e.target.value;
    if (new_amount > 0) {
      setCurrentPlan((prev) => {
        return { ...prev, Plan_Minimum_Payment: new_amount };
      });
    }
  }


  function onPlanQuantityChange(e) {
    const newQuantity = e.target.value;
    if (newQuantity > 0) {
      setCurrentPlan((prev) => {
        return { ...prev, Quantity: newQuantity };
      });
    }
  }


  function onPlanUnitChange(e) {
    const newUnit = e.target.value;
    if (newUnit > 0) {
      setCurrentPlan((prev) => {
        return { ...prev, Plan_Price: newUnit };
      });
    }
  }


  function onAddonAmountChange(e, index) {
    const new_amount = e.target.value;

    if (new_amount > 0) {
      const newList = addonList.map((item, i) => {
        if (i === index) {
          return ({ ...item, Addon_Minimim_Payment: parseInt(new_amount) })
        } else {
          return (item)
        }
      });
      setAddonList((prev) => { return ([...newList]) });
    }
  }


  function onAddonQuantityChange(value, index) {
    const newQuantity = value;
    if (newQuantity > 0) {
      const newList = addonList.map((item, i) => {
        if (i === index) {
          return ({ ...item, Quantity: parseInt(newQuantity) })
        } else {
          return (item)
        }
      });
      setAddonList((prev) => { return ([...newList]) });
    }
  }


  function onAddonUnitChange(e, index) {
    const newUnit = e.target.value;
    if (newUnit > 0) {
      const newList = addonList.map((item, i) => {
        if (i === index) {
          return ({ ...item, Addon_Price: parseInt(newUnit) })
        } else {
          return (item);
        }
      });
      setAddonList((prev) => { return ([...newList]) });
    }
  }


  function addNewAddon() {
    setAddonList((prev) => {
      if (prev) {
        return ([...prev, initialAddonState])
      } else {
        return ([initialAddonState])
      }
    });
  }

  function removeOldAddon(index) {
    const newList = addonList.map((item, i) => { return (item) });

    if (newList[index]) {
      newList?.splice(index, 1);
      setAddonList((prev) => {
        return ([...newList]);
      });
    }
  }


  function onPlanTaxSelect(val) {
    setCurrentPlan((prev) => {
      return ({ ...prev, tax_id: val?.Tax_Id })
    });
  }

  function onAddonTaxSelect(val, index) {
    let temAddon = addonList[index];
    addonList[index]['tax_id'] = parseInt(val?.Tax_Id);
    setAddonList((prev) => {
      return ([...addonList]);
    });
  }

  function onAdminSelect(val) {
    setValue("User_Ref_No", val.User_Id);
  }

  function onCouponSelect(val) {
    setCurrentCoupon(val);
  }

  function onProductSelect(val) {

    setValue("Product_Id", val.Product_Id);
    setCurrentPlan({ Plan_Price: 0, Plan_Minimum_Payment: 0, Quantity: 0, tax_id: 0 });
  }



  const addNewCustomer = async () => {
    const response = await myPromiseModal({ title: "New Customer", body: " You will be redirected to Add Customer page." })
    if (response.decision) {

      navigate(`/${currModuleId}/` + currModuleName + "/" + 'customers' + '/add_customer');
    }
  };



  return (
    <>
      <section className="mt-4  ">
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="plan ">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <section className="mx-2">
                      <div className="  ">
                        <span className="text-sm text-danger ">
                          Select Customer
                        </span>
                        <div className="name">
                          <AddSubCustomer defaultVal={{
                            label: getSubscriptionEdit?.details?.Customer_Full_Name
                              ? getSubscriptionEdit?.details?.Customer_Full_Name : Customer_Full_Name
                          }}
                            onCustomerSelect={onCustomerSelect}
                          ></AddSubCustomer>
                        </div>
                      </div>

                      <div className="flex justify-between  w-full mt-3">
                        <div>
                          <span className="text-sm font-bold">
                            Billing Address
                          </span>
                          <p style={{ color: "#45444B" }} className="text-xs">
                            {/* {selectedCustomer?.Billing_Address1} */}
                            {getValues("Billing_Address")}
                          </p>
                        </div>
                        <div>
                          <span className="text-sm font-bold">
                            Shipping Address
                          </span>
                          <p style={{ color: "#45444B" }} className="text-xs">
                            {/* {selectedCustomer?.Shipping_Address1} */}
                            {getValues("Shipping_Address")}
                          </p>
                        </div>
                      </div>

                      <div className="mt-3">
                        <span className="text-sm font-bold"> Email to</span>
                        {/* put type here */}
                        <AddSubtypeSelect
                          onEmailSendSelect={onEmailSendSelect}
                          val={getSubscriptionEdit?.details?.Email_Send_Allow_Id}
                          email={getValues("Customer_Email")} />
                        {/* {selectedCustomer?.Customer_Email_Address} */}
                      </div>

                      {(editableSalesOrder?.Sales_Order_Ref_No === undefined) &&
                        <div className=" mt-8   ">
                          <div className="text-sm font-bold">Plans</div>
                          <span className="text-sm text-danger">
                            Select product
                          </span>
                          <div className="name">
                            <AddsubProduct defaultVal={{ label: getSubscriptionEdit?.plan?.Product_Name }}
                              onProductSelect={onProductSelect}
                            ></AddsubProduct>
                          </div>
                        </div>
                      }
                    </section>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>

          {(editableSalesOrder?.Sales_Order_Ref_No === undefined) &&
            <section className="container border-2 bg-light  mt-5 ">
              <section className="mt-3 ">
                <AddSubPlanTable onPlanNameSelect={onPlanNameSelect} defaultVal={{ label: getSubscriptionEdit?.plan?.Plan_Name }}
                  onPlanAmountChange={onPlanAmountChange} currentPlan={currentPlan} onPlanQuantityChange={onPlanQuantityChange}
                  onPlanUnitChange={onPlanUnitChange} onPlanTaxSelect={onPlanTaxSelect}
                  defaultTax={{ label: getSubscriptionEdit?.plan?.Tax_Name, Tax_Id: getSubscriptionEdit?.plan?.Tax_Id }} />
              </section>
            </section>
          }


          {(editableSalesOrder?.Sales_Order_Ref_No === undefined) &&
              <section className="planTwo">
              <section className="my-5">
                <div className="text-sm font-bold">
                  <span className="text-sm   font-bold">
                    Addons
                  </span>
                  <span onClick={() => addNewAddon()}
                    style={{ backgroundColor: "#476EF8" }} className="text-sm ml-2 cursor py-2 text-white rounded px-2">
                    Add
                  </span>
                </div>
  
              </section>
              <section className="container mt-3 border-2 bg-light pb-[5vh]   ">
                <section className="mt-3  ">
                  <AddSubscriptionTable onAddonNameSelect={onAddonNameSelect}
                    onAddonUnitChange={onAddonUnitChange} onAddonTaxSelect={onAddonTaxSelect}
                     onAddonQuantityChange={onAddonQuantityChange}  addonList={addonList} 
                     removeOldAddon={removeOldAddon}/>
                </section>
              </section>
            </section>
          }
      
      {/* customer table from creating a subscription from sales order */}
      {(editableSalesOrder?.Sales_Order_Ref_No) &&

            <SubscriptionCreateSubFromSalesOrder salesOrder={editableSalesOrder} />
          }
          


          <section className="mt-5 z-0">
            <Grid container>
              <Grid item xs={6}>
                <div className="  ">
                  <span className="text-sm font-bold ">Select Coupon</span>
                  <div className="name">
                    <AddSubCoupon defaultVal={{ label: getSubscriptionEdit?.coupon?.Coupon_Name }}
                      onCouponSelect={onCouponSelect}
                    ></AddSubCoupon>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <span className="text-sm ">Sales Order Reference No</span>
                <div className="name">
                  <input
                    type="text"
                    readOnly={true}
                    placeholder="read only field"
                    className="bg-filterInput   bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    {...register("Sales_Order_Ref_No", {
                    })}
                  />
                </div>
              </Grid>
            </Grid>
          </section>

          <section className="  mt-5">
            <span className="text-md font-bold mb-5 mt-5 ">
              Subscription Term
            </span>
            <Grid container className="" spacing={2}>
              <Grid className="" item xs={6}>
                <div className="">
                  <span className="text-sm ">Subscription Number</span>
                  <div className="name">
                    <input
                      className="bg-filterInput w-[453px]  bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      readOnly={true}
                      {...register("Subscription_Ref_No", {})}
                      value={getSubscriptionEdit?.details?.Subscription_Ref_No}
                    />
                  </div>
                </div>
              </Grid>

              <Grid className="" item xs={6}>
                <div className="">
                  <span className="text-sm text-danger">Created by</span>
                  <div className="name">
                    <AddSubscriptionadminSelect
                      defaultVal={{ label: getSubscriptionEdit?.details?.Encoded_By_Name }}
                      onAdminSelect={onAdminSelect}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  {getSubscriptionEdit?.details?.Subscription_Activated_Date_String}
                  <span className="text-sm text-danger">Start on</span>
                  <div className="name">
                    <input
                      type="date"
                      onChange={onStartDateChange}
                      defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                      className="bg-filterInput w-[453px]  bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"

                    // {...register("Subscription_Activated_Date", {
                    //   required: "Start date is required",
                    // })}
                    />
                  </div>
                  <small className="text-danger">
                    {errors?.Subscription_Activated_Date &&
                      errors.Subscription_Activated_Date.message}
                  </small>
                </div>

                <section className="mt-3">
                  <span className="text-sm"> Is this a gift?</span> <br></br>
                  <SubscriptionGiftSelect
                    val={getSubscriptionEdit.details?.Gift_Id} onGiftChecked={onGiftChecked} />
                </section>

              </Grid>


              <Grid item xs={6}>
                <div className="">
                  {reOccurChecked === false ? (
                    <>
                      <span className="text-sm">Expire after</span>
                      <div className="name relative">
                        <input
                          className="bg-filterInput w-[10.7vw] bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          {...register("Subscription_Expire_Cycle_No")}
                        />

                        <div
                          style={{ border: "solid 1px black" }}
                          className="bg-white top-0 px-3 text-xs rounded-tr-md rounded-br-md  py-[2.0vh]  right-[20.1vw] absolute"
                        >
                          No of Cycles
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="mt-4 flex">
                  <div>
                    {" "}
                    <input
                      type="checkbox" checked={reOccurChecked}
                      onChange={onReOccurrChange} className="w-[0.8vw] h-[1.6vh]"
                    />
                  </div>
                  <div className="mt-[0.2vh]">
                    {" "}
                    <span className="text-xs ml-2">
                      {" "}
                      Recurring Subscription
                    </span>
                  </div>
                </div>

                {/* create invoice */}

                {(moment(startDate
                ).format("YYYY-MM-DD") < moment(Date.now()).format("YYYY-MM-DD")) &&
                  <div className="mt-4 flex">
                    <div>
                      {" "}
                      <input
                        type="checkbox" checked={createInvoiceChecked}
                        onChange={onCreateInvoiceChange} className="w-[0.8vw] h-[1.6vh]"
                      />
                    </div>
                    <div className="mt-[0.2vh]">
                      {" "}
                      <span className="text-xs ml-2">
                        {" "}
                        Create invoice for current billing cycle.
                      </span>
                    </div>
                  </div>

                }


                {/* create invoice end */}

              </Grid>
            </Grid>

            {/* <span className="text-md font-bold mb-5 mt-5 ">
            Payment Mode
          </span> */}
          </section>



          {showCustomerDetails && (
            <section className="mt-3 py-3 px-3 border-2 bg-light ">
              <Grid container>
                <Grid item xs={12}>
                  <section>
                    <section className="flex ">
                      <div className="  ">
                        <span className="text-sm font-bold ">
                          Select Customer
                        </span>
                        <div className="name mt-2">
                          <AddSubCustomer defaultVal={{ label: getSubscriptionEdit?.details?.Gift_Person_Name }}
                            onCustomerSelect={onGiftUserSelect}
                          />
                        </div>
                      </div>
                      <div className="ml-[6.1vw] mt-[4.0vh]">
                        <div

                          onClick={() => addNewCustomer()}
                          style={{
                            backgroundColor: "#476EF8",
                            color: "white",
                          }}
                          className="py-3 px-3 text-sm rounded-md"
                        >
                          ADD New Customer
                        </div>
                      </div>
                    </section>
                  </section>
                </Grid>
              </Grid>


              <section className="pt-[2vh] ">
                <Grid container className="" spacing={5}>

                  <Grid item xs={5}>
                    <div className="flex">
                      <span className="font-bold text-sm">
                        Beneficiary Id{" "}
                      </span>
                      <span className="text-xs mt-0.5 ml-3">: {giftPerson?.Customer_Id} </span>
                    </div>
                    <div className="flex">
                      <span className="font-bold text-sm">
                        Beneficiary Name
                      </span>
                      <span className="text-xs mt-0.5 ml-3">
                        : {giftPerson?.Customer_Full_Name}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="font-bold text-sm">
                        Beneficiary  Mobile{" "}
                      </span>
                      <span className="text-xs mt-0.5 ml-3">
                        : {giftPerson?.Customer_Mobile_No}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="font-bold text-sm">
                        Beneficiary  Email
                      </span>
                      <span className="text-xs mt-0.5 ml-3">:{giftPerson?.Customer_Email_Address} </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className=" ml-[1.2vw]">
                      <span className="text-sm font-bold">
                        Beneficiary Address
                      </span>
                      <br></br>
                      <span className="text-sm ">
                        {giftPerson?.Shipping_Address1}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </section>

            </section>
          )}


          <section className=" mt-2">
            <Grid container spacing={2}>
              {/* showCustomerDetails */}


              <Grid item xs={6}>


                <div className="mt-3 ">
                  <span className="text-sm pt-3  font-bold">Payment Mode</span>{" "}
                  <br></br>
                  <span className="text-sm">Payment Terms</span>
                  <div className="name">
                    <AddOnSubPay defaultVal={{
                      label: getSubscriptionEdit?.details?.Payment_Term_Name || editableSalesOrder?.Payment_Term_Name
                    }}
                      onPaymentTermsSelect={onPaymentTermsSelect} />
                  </div>
                </div>
              </Grid>


              <Grid item xs={6}>


                <div className="mt-5">
                  <span className="text-sm mt-5"> Enable partial payment </span>
                  <AddonSubPartialSub val={getSubscriptionEdit?.details?.Payment_Enable_Partial_Id}
                    allowPartial={allowPartial} />
                </div>
              </Grid>



              <Grid className="border-2 bg-light mx-3 mt-3" item xs={12}>
                <span className="text-sm">
                  {" "}
                  Select the payment gateways that customers can pay you from
                  the online payment link on the invoices.
                </span>

                <AddSubGatewaySelect editList={getSubscriptionEdit?.gateWayList} onGatewaySelect={onGatewaySelect} />
              </Grid>

              <Grid item xs={6}>
                <button style={{ color: "#F17E7E" }} className="text-md">
                  CANCEL
                </button>
              </Grid>

              <Grid item xs={6}>
                <button style={{ color: "#476EF8" }} className="text-md">
                  PREVIOUS
                </button>

                <button
                  style={{ backgroundColor: "#476EF8" }}
                  className="text-white px-5 py-2 text-md ml-2 rounded"
                >
                  SAVE AND CREATE SUBSCRIPTION
                </button>
              </Grid>
            </Grid>

            {/* <span className="text-md font-bold mb-5 mt-5 ">
             Payment Mode
           </span> */}
          </section>
        </form>
      </section>
    </>
  );
};

export default AddSubscriptionPage;
