import { useContext, useEffect, useState } from "react";
import AddItemGroupForm from "./AddItemGroupForm";
import { itemsGroupService } from "../../services/itemGroup.service";
import LogicContext from "../../Context/LogicContext"; 

import NewItemGroupInfoPage from "../NewItemGroupInfoPage";
import { useNavigate } from "react-router-dom";
import { GetGroupEdit, GetGroupImageEdit } from "../../store/itemGroupAtom";
import ItemGroupImageUpload from "./ItemGroupImageUpload";



const TAB = {GROUP_INFORMATION:'GROUP_INFORMATION', ITEM_INFORMATION:'ITEM_INFORMATION', GROUP_IMAGE:'GROUP_IMAGE'}

function AddItemGroup() {
  const navigate = useNavigate(); 
  const groupToEdit = GetGroupEdit();
  const getGroupImageEdit = GetGroupImageEdit();
    const [currentTab, setCurrentTab] = useState(TAB.GROUP_INFORMATION); 
    const logicContext = useContext(LogicContext);
    const [newItemId, setNewItemId] = useState(undefined);


    useEffect(()=> {
    setNewItemId(groupToEdit?.Item_Group_Id)
      
     
    },[groupToEdit?.Item_Group_Id])

    const changeTab = (tab)=> {
        setCurrentTab(tab)
    }


    function submitGroupInformation (data) {
        if(newItemId){
          itemsGroupService.updateItemGroup(data).then(res => {
            const {message_id, message_text, message_ref_no} = res.data[0];
            
            logicContext.showToast(message_text, 'success');
            // setCurrentTab(TAB.GROUP_INFORMATION);
            // setNewItemId(res?.data[0]['Message_Ref_No']);
            setCurrentTab(TAB.GROUP_INFORMATION);
            setNewItemId(message_ref_no);
           
        }).catch(err => {
            console.log(err);
        });
        }else{
          itemsGroupService.submitItemGroup(data).then(res => {
            const {message_id, message_text, message_ref_no} = res.data[0];
          console.log('__item_group_101', res.data);

            if(message_id === 5 || message_id === 6){
              logicContext.showToast(message_text, 'error');

            }else if (message_id === 1){
              logicContext.showToast(message_text, 'success');
              setCurrentTab(TAB.GROUP_INFORMATION);
              setNewItemId(message_ref_no);
            }
          
           
        }).catch(err => {
            console.log(err);
        });

        }
   

    }


    function submitGroupInfo (data){
        itemsGroupService.saveGroupItems(data).then(res => {
            setCurrentTab(TAB.GROUP_IMAGE);
            logicContext.showToast(res?.data[0]['message_text'], 'success');
        }).catch(e => console.log(e));
    }


    return ( 
        <>
    <section className="container max-auto max-w-6xl ">
    <section className="flex  my-3  w-60 ">
  

  <div className="  md:mt-2">
           <button
             className={
               "rounded-lg btn  px-4 py-2 mx-2 " + (currentTab === TAB.GROUP_INFORMATION ? "bg-primary text-white btn-primary" : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.GROUP_INFORMATION)}
           >
            Group Information
           </button>
  
         
           <span  >
                 <button
                 disabled={!newItemId}
             className={
               "  rounded-lg btn px-4 py-2 mx-2 " + (currentTab === TAB.ITEM_INFORMATION ? "bg-primary text-white  btn-primary" : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.ITEM_INFORMATION)}
           >
            Item  Information
           </button>
  
           
           <button
           disabled={!newItemId}
             className={
               "  rounded-lg btn  px-4 py-2 mx-2 " + (currentTab === TAB.GROUP_IMAGE ? "bg-primary  btn-primary " : "bg-neutral-300")}
             role="button"
             onClick={() => changeTab(TAB.GROUP_IMAGE)}
           >
            Pictures
           </button>
           </span> 
  
       
           
           
         </div>
       </section>
     {currentTab === TAB.GROUP_INFORMATION ? 
     <AddItemGroupForm  submitForm={submitGroupInformation} /> : null}
    
     <span>
        {currentTab === TAB.ITEM_INFORMATION ? 
        <NewItemGroupInfoPage 
        submitGroupInfo={submitGroupInfo}   item_group_id={newItemId} /> : null}

       {currentTab === TAB.GROUP_IMAGE ? 
     <ItemGroupImageUpload Item_Id={newItemId} getListToEdit={getGroupImageEdit} />  : null}
       
     </span>
     
   
    </section>
  
        </>
     );
}

export default AddItemGroup;