import React, { useRef, useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import moment from "moment/moment";
import message from "../../assets/message.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
// import InvoicePaymentTable from "./InvoicePaymentTable";
// import InvoiceDetailsTable from "./InvoiceDetailsTable";
import {
  SetCorporateEdit,
} from "../../store/corporateAtom";
import { customerService } from "../../services/customer.service";
import LogicContext from "../../Context/LogicContext";

import SampleFileViewerModal from "../customer_approval/SampleFileViewerModal";
import RatingScroll from "./RatingScroll";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RatingTypeSelect from "./RatingTypeSelect";
import RatingRangeSlider from "./RatingSlider";
import { formatCustomDate } from "../../helpers/formatCustomDate";

// import { useReactToPrint } from 'react-to-print';



const RatingDetailsPage = () => {
  const setEdit = SetCorporateEdit();
  const [selectedList, setSelectedList] = useState([]);
  const [range, setRange] = useState([1,5]);
  const myPromiseModal = createModal(GeneralModal);
  // const getBulkActions = GetInvoiceBulkAction();

  const { id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [ratingList, setRatingList] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
       document.title = 'Rating details';
       setRatingList([]);
    getInvoiceDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getInvoiceDetails = async (id) => {
    customerService.getRatingDetails(id, ...range).then((response) => {
      console.log("RAting.... ", response.data);
      if (response.data[0]) {
        setRatingList(response.data);
      }
    });
  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getInvoiceDetails(id);
  }



  async function sendRatingReply(rating) {
    await myPromiseModal({ title: `reply ${rating?.Customer_First_Name}'s rating`,
      body: ` ${rating.Rating_Remark}`,
      showInput: true,
      placeHolder: "enter your reply...",
    }).then((result) => {
      if(!result.message){
        return logicContext.showToast('Empty reply cannot be submitted!');
      }
      if (result.decision === true) {
        let bodyData = {};

        switch (id) {
          case '1':
            bodyData = {sql_ref_no:103,ref_no: rating.rating_ref_no,rating_remark: result.message,option:5};
            
            break;
          case '2':// Shopping
            bodyData = {sql_ref_no:104,salesorder_id: rating.rating_ref_no,rating_remark: result.message,option:3};
            
            break;
          case '3': // Shipping
            bodyData = {sql_ref_no:103,ref_no: rating.rating_ref_no,rating_remark: result.message,option:4};
            
            break;
          case '4': // Airtime
            bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};

            
            break;
          case '5': //
          bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};
           
            
            break;
          case '6':
          bodyData = {sql_ref_no:104, ref_no: rating.rating_ref_no,rating_remark: result.message,option:3};
            
            break;
        
          default:
            break;
        }

        customerService.submitReplyToRating(bodyData).then(res => {
          const data =  logicContext.httpResponseManager(res.data[0]);
          if(data?.message_id == 1){
            logicContext.showToast(data?.message_text, 'success');
          }
        });
       
     
      }
    });
  }


  const handlerRatingChange = ( newValue) => {
    setRange(newValue);
  };



  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <RatingScroll
                viewItemDetails={viewItemDetails}
              ></RatingScroll>

              <section className="w-full z-50  ">
                <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
                  {/* Package page  */}

                  <Grid className="mt-2 border-2 p-2" container spacing={2}>
                    <Grid item xs={8} className=" my-2">
                      <div>
                        {/* <span className="#476ef8 font-bold text-primary">{corporateData?.Corporate_Name} </span> */}
                      </div>
                      <div>
                        <div>
                          <span className="text-sm">
                            {/* Corporate ID : {corporateData?.Corporate_Short_Code} */}
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} className=" my-2">
                    
                        <div>
                          {/* Status */}
                          <span className="text-sm">
                             {/* {manageStatus(corporateData?.Corporate_Approval_Status_Id, corporateData?.Corporate_Approval_Status_Name)} */}
                          </span>
                        </div>
                   
                    </Grid>
                    <div className="flex justify-end  mb-3 mt-3 w-full">
                    <div class="d-flex justify-content-start">
                  
                        <RatingRangeSlider  handlerRatingChange={handlerRatingChange} />

                          <button onClick={()=> getInvoiceDetails(id)}
                          className=" ml-5  btn btn-primary" >apply</button>
                    </div>
                    <div className="border-2 font-weight-bold ml-4 pt-2 p-2" >
                      Total: {ratingList.length}
                    </div>
                    </div>

                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-light">
          <TableRow>
            <TableCell className="font-weight-bold" >Customer Name</TableCell>
            <TableCell align="right font-weight-bold">Mobile</TableCell>
            <TableCell align="right font-weight-bold">Email</TableCell>
            <TableCell align="right font-weight-bold">Rating</TableCell>
            <TableCell align="right font-weight-bold">Message</TableCell>
            <TableCell align="center font-weight-bold">Date</TableCell>
            <TableCell align="right font-weight-bold">Reply</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ratingList.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Customer_Full_Name}
                
              </TableCell>
              <TableCell align="left">{row.Customer_Mobile_No}</TableCell>
              <TableCell align="left">{row.Customer_Email_Address}</TableCell>
              <TableCell align="center">{row.Rating_Value}</TableCell>
              <TableCell align="center">{row.Rating_Remark}</TableCell>
              <TableCell align="center"> {formatCustomDate(row.Rating_Date)} </TableCell>
              <TableCell align="left"> 
              <button className="btn btn-sm btn-primary"  onClick={()=> sendRatingReply(row)} >Reply</button>
               </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                  </Grid>
                </section>
              </section>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default RatingDetailsPage;
