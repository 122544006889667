import React, { useContext } from "react";
// import "./CustomerDetails.css";
import ToggleSwitch from "../../components/ToggleSwitch";

import { SetGroupEdit, SetGroupImageEdit } from "../../store/itemGroupAtom";

import {
  DataGrid, gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
// import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ItemGroupDetailsScroll from "./ItemGroupDetailsScroll";
import { itemsGroupService } from "../../services/itemGroup.service";
import LogicContext from "../../Context/LogicContext";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ItemGroupImageDisplay from "./ItemGroupImagedisplay";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";



const columns = [
  {
    field: "Item_Id",
    headerName: "Id",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Item_Name",
    headerName: "Name",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Item_Sku",
    headerName: "Sku",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },

  {
    field: "Item_Category_Name",
    headerName: "Category",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Sales_Rate",
    headerName: "Sales Rate",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  {
    field: "Reorder_Quantity",
    headerName: "Current Stock",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
  // {
  //   field: "pointme",
  //   headerName: "Re Order Stock",
  //   type: "dateTime",
  //   width: 140,
  //   editable: true,
  //   headerClassName: "bg-gray-200",
  // },

  {
    field: "action",
    headerName: "Action",
    width: 140,
    // editable: true,
    headerClassName: "bg-gray-200",
  },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


function ItemGroupDetailsPage() {
  const setGroupToEdit = SetGroupEdit();
  const setGroupImageEdit = SetGroupImageEdit();
  const [itemGroupDetails, setItemGroupDetails] = useState({});
  const [imageList, setImageList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const logicContext = useContext(LogicContext);

  const { item_group_id } = useParams();
  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(() => {
    itemsGroupService.getGroupDetails(item_group_id).then((res) => {
      if(res.data?.group_info){
      setItemGroupDetails(res.data?.group_info[0]);
      } 
      
      if(res.data?.picture_info?.length){
      setImageList(res.data?.picture_info);
      }
    }).catch((err) => {
      });
  }, [item_group_id]);



  useEffect(() => {
    itemsGroupService.getGroupTabledata(item_group_id).then((res) => {
      console.log('TableList ', res?.data);
      if (res?.data.length > 0) {
        var temp = res?.data?.map((item, i) => {
          return { ...item, id: item.Item_Id };
        });
        setTableRows(temp);
      }
    });
  }, [item_group_id]);


  function getItemDetailFromServer(id) {
    itemsGroupService.getGroupDetails(id).then((res) => {
        console.log('Group ', res?.data);
        setItemGroupDetails(...res?.data);
      }).catch((err) => {
      });
  }


  function viewItemDetails(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/item-group-details/${id}`
    );
    getItemDetailFromServer(id);
  }


  const editCurrentItem = async() => {
    const response = await myPromiseModal({
      title: "Modify Item Group",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setGroupToEdit(itemGroupDetails);
      setGroupImageEdit(imageList);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-item-group`);
    }
  };


  const addNewItemHandler = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-item-group`
    );
  };


  // This method is for updating item status base on toggle response TRUE/FALSE
  const toogleItemStatus = (val) => {
    if (val === true) {
      setItemGroupDetails({ ...itemGroupDetails, Item_Group_Status_Id: 1, Item_Group_Status_Name: 'Active' });
      let xmlString = `<a><refno>${itemGroupDetails.Item_Group_Id} </refno></a>`;

      itemsGroupService.setGroupItemActive(`<root> ${xmlString} </root>`).then((res) => {
        logicContext.showToast(res.data[0]['message_text'], 'success');
      }).catch((err) => {
        logicContext.showToast('Connection error ', 'error');
        setItemGroupDetails({ ...itemGroupDetails, Item_Group_Status_Id: 2, Item_Group_Status_Name: 'Inactive' });
      });
    }
    if (val === false) {
      setItemGroupDetails({ ...itemGroupDetails, Item_Group_Status_Id: 2, Item_Group_Status_Name: 'Inactive' });
      let xmlString = `<a><refno>${itemGroupDetails.Item_Group_Id} </refno></a>`;

      itemsGroupService.setGroupItemInActive(`<root> ${xmlString} </root>`).then((res) => {
        logicContext.showToast(res.data[0]['message_text'], 'success');
      }).catch((err) => {
        logicContext.showToast('Connection error', 'error');
        setItemGroupDetails({ ...itemGroupDetails, Item_Group_Status_Id: 1, Item_Group_Status_Name: 'Active' });
      });
    }
  };

  


  return (

    <section className="  mt-3">

      <section className="  ">
        <div className="flex   flex-wrap justify-between md:">
          <div>
            <button
              onClick={addNewItemHandler}
              style={{ backgroundColor: "#476EF8" }}
              className=" h-[6vh]  rounded text-white text-xs py-1 mr-1 px-4"
            >
              ADD NEW
            </button>
          </div>

          {/* ADD NEW TRANSACTION SECTION */}
          <div className="flex">
            {/* paper, edit and pencil section */}

            <div className="flex ">
              <button
                onClick={addNewItemHandler}
                style={{ backgroundColor: "#476EF8" }}
                className=" h-[6vh]  rounded text-white text-xs py-1 mr-2 px-4"
              >
                ADD ITEM
              </button>
              <button
                style={{ borderRadius: "5px" }}
                className="   px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
              >
                <img src={trash} alt="" />
              </button>

              <button
                style={{ borderRadius: "5px" }}
                onClick={editCurrentItem}
                className=" mx-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  h"
              >
                <img src={pencil} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>

      <Grid container spacing={2}>

        <Grid item xs={3}>
          <ItemGroupDetailsScroll
            viewItemDetails={viewItemDetails}
          ></ItemGroupDetailsScroll>
        </Grid>

        <Grid item xs={9}>

          <section className="w-full z-50  ">
            <section className="  mt-2 border max-auto max-w-6xl text-filterTextColor">
              <div className="flex mx-3  mt-10">

                {itemGroupDetails.Item_Group_Status_Id === 1 ?
                  <span className="text-sm mt-[1.9vh] text-success">ACTIVE</span>
                  : <span className="text-sm mt-[1.9vh]">INACTIVE</span>
                }
                <ToggleSwitch
                  toogleItemStatus={toogleItemStatus}
                  Item_Status_Id={itemGroupDetails?.Item_Group_Status_Id}
                ></ToggleSwitch>
              </div>

              <section className="mx-2 mt-5 ">
                {/* BEANS SECTION */}
                <section className="">
                  <div className="px-2 pb-3  border w-full ">
                    <div className="px-2 mt-4">
                      <span className="font-bold">
                        {itemGroupDetails?.Item_Group_Name}{" "}
                      </span>{" "}
                      <br></br>
                      <span>
                        {" "}
                        <i>{tableRows?.length} item(s)</i>{" "}
                      </span>
                    </div>

                    <div className="">
                      <div className=" ">
                      <Grid container spacing={2}> 
                      <Grid item xs={6}>
                        <div className="flex ">
                          {" "}
                          <div className="flex ">
                            <div>
                              <div>
                                {" "}
                                <span className="text-sm">ID</span> <br></br>
                              </div>{" "}
                              <div className="my-[3vh]">
                                <span className="text-sm ">Category</span>{" "}
                                <br></br>
                              </div>
                              <div className="my-[3vh]">
                                {" "}
                                <span className="text-sm">Brand</span>
                                <br></br>
                              </div>
                              <div>
                                {" "}
                                <span className="text-sm">Manufacturer</span>
                              </div>
                            </div>

                            <div className="ml-6">
                              <div>
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Id}
                                </span>{" "}
                                <br></br>
                              </div>
                              <div className="my-[3vh]">
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Category_Name}
                                </span>{" "}
                                <br></br>
                              </div>
                              <div className="my-[3vh]">
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Brand_Name}
                                </span>
                                <br></br>
                              </div>
                              <div>
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Manufacturer_Name}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex  ml-5">
                            <div>
                              <div>
                                {" "}
                                <span className="text-sm">Type</span> <br></br>
                              </div>
                              <div className="my-[3vh]">
                                {" "}
                                <span className="text-sm">Unit</span>
                                <br></br>
                              </div>
                              <div>
                                {" "}
                                <span className="text-sm">Tax</span>
                              </div>
                            </div>

                            <div className="ml-8">
                              <div>
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Type_Name}{" "}
                                </span>{" "}
                                <br></br>
                              </div>
                              <div className="my-[3vh]">
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Unit_Name}
                                </span>
                                <br></br>
                              </div>
                              <div>
                                {" "}
                                <span className="text-sm font-bold">
                                  {itemGroupDetails.Item_Group_Tax_Name}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        </Grid>

                        <Grid item xs={6}>
                       
                    <ItemGroupImageDisplay key={imageList?.length}  imageList={imageList} />
                        
                          
                     
                        </Grid>
                        </Grid>
                      </div>
                    </div>

                  </div>


                  <section className="mt-20 border px-2 py-2 ">
                    <div
                      style={{ color: "#476EF8" }}
                      className="flex text-sm mb-8 justify-end"
                    >
                      View Opening Stock
                    </div>

                    {/* TABLE */}
                    {/* <div className="mt-7" style={{ height: 250, width: '100%' }}> */}
                    <div
                      style={{
                        height: 300,
                        width: "100%",
                        paddingBottom: "40px",
                      }}
                    >
                      <DataGrid
                      
                        columns={columns}
                        rows={tableRows?.map((item)=> { return {...item, Sales_Rate: formatCustomNumber(item?.Sales_Rate)} })}
                        rowsPerPageOptions={[6]}
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{
                          Pagination: CustomPagination,
                        }}
                      />
                      {/* </div> */}
                    </div>
                  </section>
                </section>
              </section>

            </section>
          </section>

        </Grid>



      </Grid>


    </section>

  );
}

export default ItemGroupDetailsPage;
