import { atom , useRecoilValue, useSetRecoilState, useResetRecoilState} from "recoil";


export const itemAtom = atom({key:'itemAtom', default:[]});
export const itemBackup = atom({key:'itemMasterAtom', default:[]});
export const itemHeaderAtom = atom({key:'itemHeaderAtom', default:[]});

// for edit
export const itemToEdit = atom({key:'itemToEdit', default:{}});
export const itemImageToEdit = atom({key:'itemImageToEdit', default:[]});

const itemBundleHistoryAtom = atom({key:'itemBundleHistoryAtom', default:[]});
const itemTransactionAtom = atom({key:'itemTransactionAtom', default:[]});
const itemHistoryAtom = atom({key:'itemHistoryAtom', default:[]});
const itemMasterData = atom({key:'itemMasterData', default:[]});
const comppositeItemGroupAtom = atom({key:'comppositeItemGroupAtom', default:[]});
const comppositeItemGroupAtomBackup = atom({key:'comppositeItemGroupAtomBackup', default:[]});
const associateTableListAtom = atom({key:'associateTableListAtom', default:[]});

export  const SetTItemList = () => useSetRecoilState(itemAtom);
export  const GetItemList = () => useRecoilValue(itemAtom);

export  const SetTItemHeaderList = () => useSetRecoilState(itemHeaderAtom);
export  const GetItemHeaderList = () => useRecoilValue(itemHeaderAtom);

export  const SettItemBackupList = () => useSetRecoilState(itemBackup);
export  const GetItemBackupList = () => useRecoilValue(itemBackup);

export  const SetItemToEdit = () => useSetRecoilState(itemToEdit);
export  const GetItemToEdit = () => useRecoilValue(itemToEdit);
export const ResetItemToEdit = ()=> useResetRecoilState(itemToEdit)

export  const SetItemImageToEdit = () => useSetRecoilState(itemImageToEdit);
export  const GetItemImageToEdit = () => useRecoilValue(itemImageToEdit);

export  const SetItimBundleHistory = () => useSetRecoilState(itemBundleHistoryAtom);
export const GetItemBundleHistoryValue = ()=> useRecoilValue(itemBundleHistoryAtom);


export  const SetItemTransaction = () => useSetRecoilState(itemTransactionAtom);
export const GetItemTransactionValue = ()=> useRecoilValue(itemTransactionAtom);

export  const SetItemHistory = () => useSetRecoilState(itemHistoryAtom);
export const GetItemHistory = ()=> useRecoilValue(itemHistoryAtom);


export  const SetItemMasterData = () => useSetRecoilState(itemMasterData);
export const GetItemMasterData = ()=> useRecoilValue(itemMasterData);

export  const SetICompositeGroup = () => useSetRecoilState(comppositeItemGroupAtom);
export const GetCompositeGroup = ()=> useRecoilValue(comppositeItemGroupAtom);

export  const SetICompositeGroupBackup = () => useSetRecoilState(comppositeItemGroupAtomBackup);
export const GetCompositeGroupBackup = ()=> useRecoilValue(comppositeItemGroupAtomBackup);

export  const SetAssociateTableList = () => useSetRecoilState(associateTableListAtom);
export const GetAssociateTableList = ()=> useRecoilValue(associateTableListAtom);
export const ResetAssociateTableList = ()=> useResetRecoilState(associateTableListAtom);

